<template>
  <div id="controller">
    <svg version="1.1" id="controller"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 100 100" style="width: 100%;height:100%;enable-background:new 0 0 100 100;" xml:space="preserve">

      <g id="panel">
        <circle style="background: red;" class="st0" cx="50" cy="50" r="50"/>
        <g>
      <g class="st1">
        <path class="st2" d="M14.1,21.1l0.6-0.7c0.5-0.6,1.2-0.6,1.9-0.1c0.6,0.5,0.7,1.3,0.2,1.9l-0.6,0.7L14.1,21.1z M16.2,22.6
          l0.4-0.5c0.5-0.6,0.3-1.2-0.2-1.6c-0.5-0.4-1.1-0.5-1.6,0.1L14.4,21L16.2,22.6z"/>
        <path class="st2" d="M17.3,19.7l0.9,0.9l-0.2,0.2l-2-1.9l0.7-0.7c0.4-0.4,0.8-0.4,1.1-0.1c0.3,0.2,0.3,0.6,0.1,0.8
          c0.2-0.2,0.5-0.2,0.9,0.2l0.1,0.1c0.1,0.1,0.3,0.3,0.4,0.4l-0.2,0.2c-0.1,0-0.3-0.2-0.4-0.4l-0.1,0c-0.3-0.3-0.6-0.4-0.9,0
          L17.3,19.7z M17.1,19.6l0.5-0.5c0.3-0.4,0.4-0.6,0.1-0.9c-0.2-0.2-0.5-0.2-0.8,0.1l-0.5,0.5L17.1,19.6z"/>
        <path class="st2" d="M19.4,18.1l0.4,1l-0.2,0.1l-1.1-2.7l0.2-0.2l2.7,1.3l-0.2,0.2l-0.9-0.5L19.4,18.1z M20,17.2
          c-0.8-0.4-1.2-0.6-1.4-0.7l0,0c0.1,0.2,0.3,0.7,0.6,1.4L20,17.2z"/>
        <path class="st2" d="M22.1,17l-2.4-1.7l0.2-0.1c0.6,0.4,1.9,1.4,2.1,1.6l0,0c-0.1-0.2-0.7-1.7-1-2.4l0.2-0.2
          c0.6,0.4,2,1.4,2.1,1.6l0,0c-0.2-0.4-0.7-1.8-1-2.5l0.2-0.1l1.1,2.7L23.3,16c-0.6-0.4-1.9-1.4-2.1-1.6l0,0c0.2,0.3,0.7,1.7,1,2.4
          L22.1,17z"/>
        <path class="st2" d="M95.8,46.3l-0.1-0.9l0.2,0l0.2,2.1l-0.2,0l-0.1-0.9l-2.6,0.2l0-0.2L95.8,46.3z"/>
        <path class="st2" d="M94.8,49.6l0-1.4l-1.2,0l0.1,1.6l-0.2,0L93.3,48l2.8-0.1l0.1,1.7l-0.2,0l-0.1-1.5l-1,0l0,1.4L94.8,49.6z"/>
        <path class="st2" d="M93.4,51.9l1.3-0.8v0l-1.2-0.9l0-0.3l1.4,1l1.4-0.9l0,0.3L95,51.1v0l1.2,0.8l0,0.3l-1.3-1l-1.5,0.9
          L93.4,51.9z"/>
        <path class="st2" d="M95.9,53.3l0.1-0.9l0.2,0L96,54.4l-0.2,0l0.1-0.9l-2.6-0.2l0-0.2L95.9,53.3z"/>
        <path class="st2" d="M25.8,87.5l0.8-0.6l0.2,0.1l-2.4,1.8l-0.2-0.1l0.7-2.9l0.2,0.1l-0.2,1L25.8,87.5z M24.8,87.1
          c-0.2,0.9-0.3,1.3-0.4,1.5l0,0c0.2-0.1,0.6-0.5,1.2-0.9L24.8,87.1z"/>
        <path class="st2" d="M22.6,87.6l1-1.4c0.5-0.6,0.2-1-0.1-1.2c-0.4-0.3-0.8-0.3-1.2,0.3l-1,1.4l-0.2-0.1l1-1.3
          c0.5-0.7,1.1-0.6,1.5-0.3c0.4,0.3,0.6,0.9,0.1,1.5l-1,1.4L22.6,87.6z"/>
        <path class="st2" d="M20.4,85.9l-0.7-0.6c-0.6-0.5-0.6-1.3-0.1-1.9c0.5-0.6,1.3-0.7,1.9-0.2l0.7,0.6L20.4,85.9z M22,83.8
          l-0.5-0.4c-0.6-0.5-1.2-0.3-1.6,0.2c-0.4,0.5-0.5,1.1,0.1,1.6l0.5,0.4L22,83.8z"/>
        <path class="st2" d="M18.1,83.8l1.9-2l0.2,0.1l-1.9,2L18.1,83.8z"/>
        <path class="st2" d="M16.8,80.5c0.6-0.5,1.3-0.7,1.9-0.1c0.5,0.6,0.4,1.3-0.2,1.9c-0.6,0.5-1.3,0.7-1.9,0.1
          C16.1,81.8,16.2,81.1,16.8,80.5z M18.4,82.2c0.5-0.4,0.7-1.1,0.2-1.6c-0.5-0.5-1.1-0.3-1.6,0.1c-0.5,0.5-0.6,1.1-0.2,1.6
          C17.3,82.8,17.9,82.6,18.4,82.2z"/>
      </g>
    </g>
        <g id="borders">
		<g class="st5">
			<g>
				<line class="st6" x1="50.2" y1="50.4" x2="50.5" y2="49.9"/>
        <line class="st7" x1="51" y1="49.1" x2="74.5" y2="8.5"/>
        <line class="st6" x1="74.7" y1="8" x2="75" y2="7.6"/>
			</g>
		</g>
          <g class="st5">
			<g>
				<line class="st6" x1="1" y1="50.1" x2="1.5" y2="50.1"/>
        <line class="st8" x1="2.5" y1="50.1" x2="98.6" y2="50.1"/>
        <line class="st6" x1="99.1" y1="50.1" x2="99.6" y2="50.1"/>
			</g>
		</g>
          <g class="st5">
			<g>
				<line class="st6" x1="50.2" y1="50.4" x2="50.5" y2="50.8"/>
        <line class="st9" x1="51" y1="51.6" x2="74.4" y2="92.2"/>
        <line class="st6" x1="74.6" y1="92.6" x2="74.8" y2="93"/>
			</g>
		</g>


          <!--				<path d="M50.5,32.9c2.9,0,5.8,0.7,8.3,2.1L50,50.3l-0.1,0.2l0.1,0.3l8.6,14.9c-2.5,1.3-5.3,2-8.1,2c-2.9,0-5.9-0.8-8.5-2.2-->
          <!--					c-8.4-4.7-11.4-15.3-6.7-23.7C38.4,36.3,44.2,32.9,50.5,32.9 M50.5,32.4c-6.3,0-12.4,3.3-15.6,9.2c-4.8,8.6-1.7,19.5,6.9,24.4-->
          <!--					c2.8,1.5,5.7,2.3,8.7,2.3c3.1,0,6.1-0.8,8.8-2.3l-8.8-15.3l9.1-15.7c-0.1-0.1-0.2-0.1-0.3-0.2C56.5,33.1,53.5,32.4,50.5,32.4-->
          <!--					L50.5,32.4z"/>-->


          <!--          <circle id="border" class="st11" cx="50.2" cy="50.4" r="45" fill="red"/>-->
        </g>

        <!--Clickable areas-->
        <g id="icons">
<!--          Todo: sketch & audio wont highlight because they have no background element -->
        <g id="a_sketch" v-touch:tap="()=>onAction('sketch')" :class="appState.highlightedContextItems.includes('sketch') && 'highlight'">
          <path class="st3" d="M70.2,15.8c-19.1-11-43.6-4.5-54.6,14.6c-3.6,6.3-5.4,13.2-5.4,20l40,0L70.2,15.8z"/>
          <g style="translate3d(-4px,-4px,0)">
            <path class="st12" d="M31.8,31.6l-3.5-0.4c-0.6-0.1-2.1,1.3-2.5,0.8l0.3-1.5c0.1-0.6-0.6-1.2-1.1-0.8c-1.9,1.2-4.9,2.8-4,0.4"/>
            <path d="M38.5,23.9l-5.4,5.4l-0.4,1.2l1.2-0.3l5.4-5.4C39.3,24.7,38.5,23.9,38.5,23.9z M39.4,23l0.8,0.8L41,23
              c0.1-0.1,0.1-0.3,0-0.4l-0.4-0.4c-0.1-0.1-0.3-0.1-0.4,0L39.4,23L39.4,23z M41.4,21.4l0.4,0.4c0.6,0.6,0.6,1.5,0,2.1l-7.3,7.3
              L32,31.9c-0.3,0.1-0.6-0.1-0.7-0.4c0-0.1,0-0.2,0-0.3l0.7-2.5l7.3-7.3C39.9,20.8,40.9,20.8,41.4,21.4L41.4,21.4z"/>
          </g>

        </g>
          <g id="a_audio" v-touch:tap="()=>onAction('audio')" :class="appState.highlightedContextItems.includes('audio') && 'highlight'">
          <path class="st3" d="M10.3,50.3c0,13.8,7.2,27.2,20,34.6s28,6.9,40,0l-20-34.6L10.3,50.3z"/>
            <path id="icon_audio" style="transform: translate3d(-4px,4px,0)" d="M34.4,68.7c-0.9,0-1.7,0.8-1.7,1.7v3.4c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7v-3.4
            C36.1,69.4,35.4,68.7,34.4,68.7z M34.4,67.5c1.6,0,2.8,1.3,2.8,2.8v3.4c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8v-3.4
            C31.6,68.8,32.9,67.5,34.4,67.5z M29.3,73.8c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6c0,2.2,1.8,3.9,3.9,3.9l0,0h0
            c2.2,0,3.9-1.8,3.9-3.9c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6c0,2.8-2.3,5.1-5.1,5.1h0C31.6,78.8,29.3,76.6,29.3,73.8z"/>
        </g>
          <g id="a_h2" v-touch:tap="()=>onAction('default', 'h2')" :class="appState.highlightedContextItems.includes('h2') && 'highlight'">
          <path class="st4 st4_1" d="M50.2,50.3l24.6,43.1c7.3-4.2,13.6-10.3,18.1-18.1c4.4-7.7,6.6-16.8,6.6-25.1L50.2,50.3z"/>
            <g id="icon_H2" style="transform: translate3d(4px,4px,0)">
            <path d="M76.9,60.2c-0.9,0.1-1,0.1-1,1v4.2c0,0.9,0.1,0.9,1,1v0.3h-2.8v-0.3c0.9-0.1,1-0.1,1-1v-2.1h-3.6v2.1
              c0,0.9,0.1,0.9,0.9,1v0.3h-2.8v-0.3c0.9-0.1,0.9-0.1,0.9-1v-4.2c0-0.9-0.1-0.9-0.9-1v-0.3h2.8v0.3c-0.8,0.1-0.9,0.1-0.9,1v1.7
              H75v-1.7c0-0.9-0.1-0.9-1-1v-0.3h2.8V60.2z"/>
              <path d="M80.9,62c-0.1,0.4-0.2,0.8-0.3,1.1h-2.9v-0.2c1.1-1,2-1.7,2-2.6c0-0.6-0.3-0.9-0.8-0.9c-0.5,0-0.8,0.3-1,0.6l-0.2-0.2
            l0.4-0.5c0.3-0.4,0.7-0.5,1.2-0.5c0.7,0,1.3,0.5,1.3,1.3c0,0.5-0.3,1-1.2,1.8l-0.7,0.6h1.3c0.4,0,0.5-0.1,0.8-0.6L80.9,62z"/>
          </g>
        </g>

          <g id="a_h1" v-touch:tap="()=>onAction('default', 'h1')" :class="appState.highlightedContextItems.includes('h1') && 'highlight'">
          <path class="st4 st4_1" d="M74.9,7.7L50.2,50.3l49.3-0.1C99.7,33,90.8,16.9,74.9,7.7z"/>

            <g id="icon_H1" style="transform: translate3d(4px,-4px,0)">
              <path d="M78.6,33.8c-1.1,0.1-1.2,0.2-1.2,1.3v5.4c0,1.1,0.1,1.2,1.2,1.3v0.4H75v-0.4c1.1-0.1,1.2-0.2,1.2-1.3v-2.7h-4.6v2.7
                c0,1.1,0.1,1.2,1.2,1.3v0.4h-3.6v-0.4c1.1-0.1,1.2-0.2,1.2-1.3v-5.4c0-1.1-0.1-1.2-1.2-1.3v-0.4h3.6v0.4
                c-1.1,0.1-1.2,0.2-1.2,1.3v2.1h4.6v-2.1c0-1.1-0.1-1.2-1.2-1.3v-0.4h3.6V33.8z"/>
              <path d="M79.7,37.7v-0.3c0.8-0.1,0.9-0.2,0.9-0.9v-2.9c0-0.6-0.1-0.6-0.6-0.7l-0.3,0v-0.3c0.7-0.1,1.3-0.2,1.8-0.5v4.4
                c0,0.7,0,0.8,0.9,0.9v0.3H79.7z"/>
            </g>
        </g>

          <g id="a_default" v-touch:tap="()=>onAction('default', 'default')" :class="appState.highlightedContextItems.includes('default') && 'highlight'">
          <ellipse class="st4 st4_2" cx="49.9" cy="50" rx="18" ry="18"/>
            <ellipse class="st4" cx="49.9" cy="50" rx="17.1" ry="17.4"/>
<path d="M52.7,48.9c-0.1-0.4-0.2-0.7-0.4-0.9c-0.2-0.3-0.3-0.4-0.9-0.4h-1.1V53c0,0.9,0.1,1,1.2,1.1v0.3h-3.3v-0.3c1-0.1,1.1-0.2,1.1-1.1v-5.5h-1c-0.6,0-0.8,0.1-1,0.4c-0.1,0.2-0.2,0.5-0.4,0.9h-0.3c0.1-0.7,0.1-1.4,0.2-2H47c0.2,0.3,0.3,0.3,0.6,0.3h4.5c0.3,0,0.4,0,0.6-0.3h0.3c0,0.5,0,1.3,0.1,2L52.7,48.9z"/>
</g>
      </g>

    </g>
  </svg>

  </div>
</template>
<script>
/**
 * @component Controller
 * This is the control for the user to add new items / nodes to the stage
 */
import { appStateStore } from "@/store/appState";

export default {
  emits: ['action-add'],
  setup(props, {emit}) {

    const appState = appStateStore()

    const onAction = (type, subType) => {
      // console.log('TT', props.appState.contextItemsEnabled, type, subType)
      if (appState.contextItemsEnabled.includes(type) || appState.contextItemsEnabled.includes(subType))
        emit('action-add', {type: type, subType: subType})
    }

    return {
      onAction,
      appState
    }
  }
}
</script>

<style lang="scss" scoped>
#controller {
  cursor          : pointer;
  backdrop-filter : blur(3px);
}

.st0 {
  opacity : 0.25;
  fill    : #a91111;
}

.st1 {
  display : none;
}

.st2 {
  display : inline;
  fill    : #828282;
}

.st3 {
  fill    : #E2E2E2;
  opacity : 0.1;
}

.st4 {
  fill : #cfcfcf;
}

.st4_1 {
  fill-opacity : 0.5;
}

.st4_2 {
  stroke-width      : 1;
  stroke            : #8f8f8f;  // inner circle-border
  stroke-dasharray  : 75 25;
  stroke-dashoffset : 81;
}

.st5 {
  opacity : 1;
}

.st6 {
  fill              : none;
  stroke            : #000000;
  stroke-width      : 0.25;
  stroke-miterlimit : 10;
}

.st7 {
  fill              : none;
  stroke            : #000000;
  stroke-width      : 0.25;
  stroke-miterlimit : 10;
  stroke-dasharray  : 0.9872, 0.9872;
}

.st8 {
  fill              : none;
  stroke            : #000000;
  stroke-width      : 0.25;
  stroke-miterlimit : 10;
  stroke-dasharray  : 0.9913, 0.9913;
}

.st9 {
  fill              : none;
  stroke            : #000000;
  stroke-width      : 0.25;
  stroke-miterlimit : 10;
  stroke-dasharray  : 0.9847, 0.9847;
}

.st10 {
  fill : #070707;
}

.st11 {
  display           : none;
  opacity           : 0.3;
  fill              : none;
  stroke            : #000000;
  stroke-width      : 0.25;
  stroke-miterlimit : 10;
  stroke-dasharray  : 4, 2;
}

.st12 {
  fill              : none;
  stroke            : #000000;
  stroke-width      : 0.75;
  stroke-linecap    : round;
  stroke-linejoin   : round;
  stroke-miterlimit : 10;
}

// Line...
.st12 {
  //display: none;
  stroke-width : 0.25;
}

// Dark background
.dark {
  .st11, .st12, .st9, .st8, .st7, .st6 {
    stroke : white;
  }

  .st3 {
    opacity : 0; // icon bg areas
  }

  .st0, .st4 {
    fill : black !important;
  }

  #icons path {
    fill : white;
  }

  #icon_node path {
    fill : black;
  }
}

// Highlighted context items (overrides all)
.highlight {

  .st0, .st4, .st4_1, .st3 {
    fill                       : red !important;
    transition-timing-function : ease-in-out;
    animation                  : pulse 1.5s linear infinite;
  }

}

@keyframes pulse {
  0% {
    opacity : 1;
  }
  50% {
    opacity : 0.3;
  }
  100% {
    opacity : 1;
  }
}


</style>
