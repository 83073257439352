<script setup>
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute, useRouter }                                                       from "vue-router";
import { storeToRefs }                                                               from "pinia";
import { uuid }                                                                      from "vue-uuid/index.mjs";
import Stage                                                                         from "@/views/Stage";
import { appStateStore }                                                             from "@/store/appState";
import { useProjectStore }                                                           from "@/store/project";
import OptionsPanel                                                                  from "@/components/OptionsPanel";
import RTE                                                                           from "@/components/RTE";
import HelperOverlay                                                                 from "@/views/HelperOverlay";
import FiButton                                                                      from "@/components/fiButton";

/* eslint-disable */
// Settings ------------------------
const autoSave = true
// <---- Settings ------------------

const router = useRouter()
const route = useRoute()

// AppState -----------------------
const appState = appStateStore()
const {help, helpEnabled, wait} = storeToRefs(appState)

// Project --------------------------
const store = useProjectStore()
const {project, leftStage, rightStage} = storeToRefs(store)

// Options || Stages -------------------------
const stage0Ref = ref(null)
const stage1Ref = ref(null)
const stagePosition = ref(null)   // Stage position transfer variable. Just used to update miniView
const split = ref(false)          // Split screen or not

// Current stage is determined BY STORE POINTER to a node (parent)
const currentStage = computed(() => (project.value.currentParentNodePointer === 0) ? leftStage.value : rightStage.value)

// Optionspanel ------------------
const optionsPanelActive = ref(false)
const optionsPanel = ref(null)

// Currently selected node -------
const selectedNode = ref(null)

// ----------------------------------------------------------------
// Watchers
// ----------------------------------------------------------------

// Autosave on dirty
let autoSaveTimer = null
watch(() => appState.dirty,
    () => {
      // -------------> AutoSave
      clearTimeout(autoSaveTimer)
      autoSaveTimer = setTimeout(() => {
        if (autoSave)
          save(false)
      }, 10000) // 10 sec.
    })


// -------------------------------------------------------------
// RTE
// -------------------------------------------------------------
const rteRef = ref(null)
const rteNode = ref(null)

const onOpenRTE = async (node, fromStage) => {
  // If one is open, close it first (destroy and rebuild)
  if (rteNode.value) {
    split.value = false
    await _checkRTEAndSave()
    rteNode.value = null
  }
  nextTick(() => {
    // Go on
    split.value = true
    rteNode.value = node
  })

}

// Check for RTE data and save. Used at multiple points
// Gets data directly from current editor
const _checkRTEAndSave = async () => {
  if (!rteRef.value || !rteNode.value) return false
  let node = await rteRef.value.getData()
  await store.updateNode(node) // OLD node
  return true
}

// ----------------------------------------------------------------
// Split view / stages
// ----------------------------------------------------------------


/**
 * Split view is requested --> go one down into the node
 * and use the "level" methods
 *
 * @param payload
 */
const onOpenSplit = async (payload, fromStage) => {
  // If RTE is open, save its contents
  if (rteNode.value) {
    await _checkRTEAndSave()
    rteNode.value = null
  }
  nextTick(() => {
    // Check from which stage it came and switch to the other
    _switchStage({node: payload}, fromStage === 0 ? 1 : 0, true)
  })

  //
  updateHelp()
}

/**
 * Close split view.
 * Currently we can only close right hand (1)
 * @param fromStage
 */
const onCloseSplit = async () => {
  await _checkRTEAndSave()
  project.value.currentParentNodePointer = 0 // Currently always stage 1, so reset pointer:
  // project.value.currentParentNodes[1] = null
  split.value = false
  rteNode.value = null
}


// -------------------------------------------------------------
// Stages
// -------------------------------------------------------------

/**
 * Stage is focused. Set it active (for store)
 * This is just a pointer
 * @param id
 */
const onStageFocus = (id) => {
  project.value.currentParentNodePointer = id
}

/**
 * Update stage record in store
 * @param payload JSON - must be a complete model stage record {}
 */
const updateStage = async (payload) => {

  // ------ Help
  updateHelp(payload)

  store.updateStage(payload)
  // Refresh connector colours, use dom refs.
  // if (project.value.currentParentNodePointer === 0)
  //   stage0Ref.value.refreshConnectors()
  // else
  //   stage1Ref.value.refreshConnectors()


}

/**
 * Switch a stage
 * @param payload JSON {node: <node> id | content}
 * @param toStage
 * @param splitParam
 * @returns {Promise<void>}
 * @private
 */
const _switchStage = async (payload, toStage = 0, splitParam = false) => {
  // unselect originated node
  selectedNode.value = null
  // store originating stage
  const originatingStagePointer = toStage === 0 ? 1 : 0
  const originatingStage = originatingStagePointer === 0 ? leftStage.value : rightStage.value
  // Disable optionspanel
  optionsPanelActive.value = false
  // Set current project set to the nodes.components array && add a new stage if not there
  const r = await store.switchStage({
    currentParentNodePointer: toStage,                                         // 0 || 1 in split mode
    node: {id: payload.node.id, content: payload.node.content},   // The node to which this stage belongs
    preset: {                                                     // If this is a new stage, then use the styles from the parent.
      title: payload.node.content,
      style: originatingStage?.style,
      background: originatingStage?.background
    }
  })

  // New stage should be in DOM now:
  nextTick(() => {
    // Set split, if requested (do **not** unset)
    if (splitParam)
      split.value = true
    project.value.currentParentNodePointer = toStage

    // Center? If new only. Or pan to parent node that openend stage 1
    if (r.new)
      onCenterStage(toStage)
    else // Position stage to last active position
      onMoveStage({pos: project.value.currentParentNodes[project.value.currentParentNodePointer].position})
  })

}

/**
 * Move nodes across stages
 * @param node
 * @param targetStagePointer
 */
const onMoveToStage = (node, targetStagePointer) => {
  const copy = false  // copy or move
  const sourceStagePointer = targetStagePointer === 0 ? 1 : 0
  // Only allow nodes which are not the stage itself
  if (node.component.id.toString() === project.value.currentParentNodes[targetStagePointer].id.toString()) return

  // Enable other stage
  project.value.currentParentNodePointer = targetStagePointer

  // Get node contents and copy across
  const _newId = uuid.v4()
  const targetStage = project.value.stages.find(s => s.id.toString() === project.value.currentParentNodes[targetStagePointer].id.toString())
  const sourceStage = project.value.stages.find(s => s.id.toString() === project.value.currentParentNodes[sourceStagePointer].id.toString())

  console.log('Target Stage ', project.value.currentParentNodes, project.value.stages,)
  // New Position on other stage = center X and keep node position y virtually the same
  let xOffset = Math.abs(targetStage.position.x) + window.innerWidth / 4 - node.component.position.w / 2
  let yOffset = Math.abs(targetStage.position.y) + node.component.position.y - sourceStage.position.y
  let nPos = {...node.component.position, ...{x: xOffset, y: yOffset}}

  // Copy or move: new Id or same.
  const _node = copy
      ? {...node.component, ...{id: _newId}, ...{position: nPos}}         // new id, new node
      : {...node.component, ...{position: nPos}}                        // move to the target stage

  if (copy) {
    // Todo: NYI
    // Copy the node and transfer (copy) its sub-stages to the new one
    // project.addNode(_node)  // move the node across
    // // Copy all sub-stages (recursive)
    // let stageofNode = project.value.stages.find(s => s.id === node.component.id)
    // if (stageofNode) {
    //   // Update with new node id.
    //   store.transferStage(_node, toStageId)
    // }
  } else {
    // Move. Change the node's stage (move to the target stage)
    store.transferNode(_node, project.value.currentParentNodes[project.value.currentParentNodePointer].id)
  }

  // ----- Help : last
  if (help.value.pointer === 16) {
    help.value = {pointer: 17, x: window.innerWidth - 280, y: window.innerHeight - 160, absolute: true}
  }
}

/**
 * Trigger stage to editor center
 * @param stageId
 */
const onCenterStage = (stageId) => {
  // return
  nextTick(() => {
    // if (stageId === 1 && stage1Ref.value)
    //   stage1Ref.value.centerStage()
    // else if (stageId === 0 && stage0Ref.value)
    //   stage0Ref.value.centerStage()
  })
}

/**
 * Move Stage event
 * This can originate from the miniView and move the current main stage along
 * @param e
 */
const onMoveStage = (e) => {
  // console.log('moving stage to ', e)
  if (project.value.currentParentNodePointer === 1) {
    stage1Ref.value && stage1Ref.value.moveStage(e)
  } else
    stage0Ref.value && stage0Ref.value.moveStage(e)
  // If set, set
  if (e.set) {
    updateStage({position: e.pos})
  }

}


// ---------------------------------------------------------------
// Levels
// ---------------------------------------------------------------

/**
 * Go one level down: switch or create
 * @param payload {component} the parent node
 * @param stageId int of stage calling
 */
const onLevelDown = async (payload, stageId = 0) => {
  project.value.currentParentNodePointer = stageId // safer
  _switchStage({node: {...payload.component}}, stageId, false)
  // Position stage to last active position
  onMoveStage({pos: project.value.currentParentNodes[project.value.currentParentNodePointer].position})
  // ------- Help
  updateHelp(component)

}

/**
 * Go one level up and update stage content / nodes
 */
const onLevelUp = (currentParentNodePointer = 0) => {
  project.value.currentParentNodePointer = currentParentNodePointer // safer
  const pNode = project.value.nodes.find(n => n.id.toString() === project.value.currentParentNodes[currentParentNodePointer].id.toString())
  const parentStage = project.value.stages.find(s => s.id === pNode?.stageId)
  _switchStage({
    node: {...parentStage, ...{content: pNode?.content}},      // use parent node id to go up
  }, project.value.currentParentNodePointer)

  // Position stage to last active position
  onMoveStage({pos: project.value.currentParentNodes[project.value.currentParentNodePointer].position})

  // ------- Help
  updateHelp()


}


// ---------------------------------------------------------------
// Nodes
// ---------------------------------------------------------------

/**
 * Activate a node, toggle options panel for it.
 * @param payload
 */
const onNodeSelect = (payload, stageId = 0) => {
  project.value.currentParentNodePointer = stageId // safer
  // No payload = deselect
  if (!payload) {
    selectedNode.value = null
    // ------- Help
    updateHelp()
    return
  }
  //
  const {active, component} = payload

  // ------- Help
  updateHelp(component)

  if (selectedNode.value === payload) return
  // Set active node / or null
  selectedNode.value = component
  // Toggle optionspanel
  optionsPanelActive.value = active
}

/**
 * Add a new node:
 * @param payload OBJ of node
 * @param stageId INT 0|1 the stage addressed
 * @returns {Promise<void>}
 */
const onAddNode = async (payload, stageId = 0) => {
  project.value.currentParentNodePointer = stageId // safer
  await store.addNode(payload, currentStage.value?.id)

  // ------- Help
  updateHelp(payload)
}

/**
 * Remove a node. And its children
 * @param payload
 * @returns {Promise<void>}
 */
const onRemoveNode = async (payload) => {
  const {node} = payload
  // Close split, if it belongs to removed node
  if (rightStage.value?.id === node.id) split.value = false
  await store.deleteNode(node)
  optionsPanelActive.value = false
}

/**
 * Update a single node
 * Todo: pure updates are made by nodes themselves.
 */
const onUpdateNode = (payload, currentParentNodePointer = 0) => {
  const {node, attachment, stageId} = payload
  project.value.currentParentNodePointer = currentParentNodePointer // safer ????

  selectedNode.value = node
  store.updateNode(node, attachment, stageId)
}

/**
 * Node options are updated.
 * The questions is WHY do we not use onUpdateNode ? ~because it felt safer to not use too many spreads...
 * @param opts
 * @param stageId
 */
const onUpdateNodeOptions = (opts) => {
  if (!selectedNode?.value) return
  selectedNode.value = {...selectedNode.value, ...{options: {...selectedNode.value.options, ...opts}}}
  store.updateNode(selectedNode.value)


  // ------- Help
  updateHelp()

}


// ----------------------------------------------------------------
// Connectors
// ----------------------------------------------------------------

/**
 * Add a connector between nodes
 * @param payload
 * @param currentParentNodePointer
 */
const onAddConnector = (payload, currentParentNodePointer = 0) => {
  project.value.currentParentNodePointer = currentParentNodePointer // safer
  store.addConnector(payload, currentStage.id)
  // ---- Help
  updateHelp()

}

/**
 * Remove a connector between nodes
 * @param payload
 * @param currentParentNodePointer
 */
const onRemoveConnector = (payload, currentParentNodePointer = 0) => {
  project.value.currentParentNodePointer = currentParentNodePointer // safer
  store.removeConnector(payload, currentStage.id)
  // ---- Help
  updateHelp()
}

/**
 * Update a connector
 * @param payload
 */
const onUpdateConnectorOptions = (payload, currentParentNodePointer = 0) => {
  project.value.currentParentNodePointer = currentParentNodePointer // safer
  store.updateConnectorOptions(payload, currentStage.value?.id)
}

// ----------------------------------------------------------------
// OptionsPanel
// ----------------------------------------------------------------
const onDisableOptionsPanel = () => {
  // Todo: improve. Enough to deselect active node && toggleSubNav in watcher
  optionsPanelActive.value = false
  // Hode all subNavs / tracks
  optionsPanel.value.toggleSubNav()
}


// ----------------------------------------------------------------
// Undo / Redo
// ----------------------------------------------------------------
const canUndo = computed(() => store.canUndo())
const undo = () => {
  store.undo()
}
const redo = () => {
  store.redo()
}

// ----------------------------------------------------------------
// Save and sync
// ----------------------------------------------------------------
const save = async (exit = false) => {

  console.warn('Saving...', project.value)
  // Save RTE contents, if active
  if (rteNode.value) {
    await _checkRTEAndSave()
  }
  // Save
  await store.putToDb()
  // If user wants to exit, clean up first
  if (exit) {
    // Stop any timer
    clearTimeout(autoSaveTimer)
    autoSaveTimer = null
    // just navigate. onBeforeUnload will do the rest
    router.push('/projects')
  }
  return true
}

// ----------------------------------------------------------------
// Help methods
// ----------------------------------------------------------------
const toggleTour = () => {
  helpEnabled.value = !helpEnabled.value
  if (!helpEnabled.value) appState.resetHelpState()
}

const updateHelp = (node) => {

  if (!helpEnabled.value) return

  if (help.value.pointer === 0) {
    help.value = {pointer: 1, x: node.position.x + node.position.w + 40, y: node.position.y - node.position.h / 1.5}
  }
  if (help.value.pointer === 1) {
    // First node created. Now check the 1st node position and place this help item : somewhere away from it.
    help.value = {...help.value, ...{pointer: 2}}
  }
  if (help.value.pointer === 2) {
    // First node created. Now check the 1st node position and place this help item : somewhere away from it.
    help.value = {pointer: 3, x: node.position.x + node.position.w + 40, y: node.position.y - node.position.h / 1.5}
  }
  if (help.value.pointer === 3) {
    // First node created. Now check the 1st node position and place this help item : somewhere away from it.
    help.value = {...help.value, ...{pointer: 4}}
  }
  if (help.value.pointer === 4) {
    // Next: added a connector.
    help.value = {...help.value, ...{pointer: 5}}
  }
  if (help.value.pointer === 5) {
    // Next: remove a connector.
    help.value = {...help.value, ...{pointer: 6, x: project.value.nodes[0].position.x, y: project.value.nodes[0].position.y - 150, absolute: false}}
    // stage0Ref.value.deselectActiveNodes(null)
  }
  if (help.value.pointer === 6) {
    // Make sure, node 0 is selected
    const n = project.value.nodes.findIndex(item => node.id === item.id)
    // Move to font settings, ONLY when node 0 was selected
    if (n === 0)
      help.value = {pointer: 7, x: window.innerWidth - 860, y: window.innerHeight - 180, absolute: true}
  }
  if (help.value.pointer === 7) {
    // First node created. Now check the 1st node position and place this help item : somewhere away from it.
    help.value = {...help.value, ...{pointer: 8, x: window.innerWidth - 280, y: window.innerHeight - 160, absolute: true}}
  }
  if (help.value.pointer === 8 && leftStage.value.background !== node.background) {
    // User has selected a new background
    help.value = {...help.value, ...{pointer: 9, absolute: false}}
  }
  if (help.value.pointer === 9) {
    // Make sure, node 1 is selected
    const n = project.value.nodes.findIndex(item => node.id === item.id)
    if (n === 1)
      help.value = {pointer: 10, x: node.position.x, y: node.position.y + node.position.h + 50}
  }
  if (help.value.pointer === 10) {
    // help.value = {pointer: 11, x: project.value.currentParentNodes[project.value.currentParentNodePointer].position.x, y: project.value.currentParentNodes[project.value.currentParentNodePointer].position.y}
    help.value = {pointer: 11, x: 0, y: 0}
  }
  if (help.value.pointer === 11) {
    help.value = {pointer: 12, x: node.position.x + node.position.w + 20, y: node.position.y - node.position.h / 2}
  }
  if (help.value.pointer === 12) {
    help.value = {pointer: 13, x: 200, y: 5, absolute: true}
  }
  if (help.value.pointer === 13) {
    help.value = {pointer: 14, x: project.value.nodes[1].position.x + 70, y: project.value.nodes[1].position.y + project.value.nodes[1].position.h + 40, absolute: false}
  }
  if (help.value.pointer === 14) {
    help.value = {pointer: 15, x: window.innerWidth / 2 - 125, y: window.innerHeight / 2, absolute: false}
  }
  if (help.value.pointer === 15) {
    help.value = {pointer: 16, x: node.position.x + node.position.w + 60, y: node.position.y - node.position.h / 2, absolute: false}
  }
}

// ----------------------------------------------------------------
// Lifecycle events & actions
// ----------------------------------------------------------------
onBeforeMount(async () => {
  if (!route.params.projectId) {
    throw new Error('This project does not exist. Create?')
  } else {
    await store.getProject(route.params.projectId)
    // The store should instantly provide the project. IF NOT, then return from above
    if (!project.value)
      router.push('/')

    // Center, if new ( only working when stage[0] is always start )
    if (!project.value?.nodes.length && project.value?.currentParentNodes[0].id === 0) onCenterStage(0)

    // Keep stage 0 focused
    // project.value.currentParentNodePointer === 0
    // ReOpen split, if parentNode[1] has content
    // if (project.value.currentParentNodes[1]) {
    //   split.value = true
    // }
  }

})

// Unmounting should never happen without save interaction. Unless it's dev and we reload.
onBeforeUnmount(async () => {
  // Save on exit
  if (appState.dirty.value)
    await save(true)
})

onMounted(() => {
  help.value = {...help.value, ...{pointer: 0}}
})


</script>

<template>
  <div>
    <div v-if="project" :class="['stagewrapper', split && 'split', appState.wait && 'wait']">

      <HelperOverlay ref="refHelp"/>

      <Stage
          v-if="split && rightStage"
          tabindex="1"
          ref="stage1Ref"
          :key="rightStage?.id + rightStage?.background"
          :app-state="appState"
          :stage="rightStage"
          :split-index="1"
          :split="split"
          @stage:focus="onStageFocus(1)"
          @stage:update="updateStage($event, 1)"
          @stage:position="stagePosition=$event"
          @stage:nodeselect="onNodeSelect($event, 1)"
          @stage:nodedeselect="onNodeSelect($event, 1)"
          @stage:updatenode="onUpdateNode($event, 1)"
          @stage:addnode="onAddNode($event, 1)"
          @stage:removenode="onRemoveNode($event, 1)"
          @stage:addconnector="onAddConnector($event, 1)"
          @stage:removeconnector="onRemoveConnector($event, 1)"
          @stage:updateconnectoroptions="onUpdateConnectorOptions($event, 1)"
          @stage:leveldown="onLevelDown($event, 1)"
          @stage:levelup="onLevelUp($event, 1)"
          @node:moveleft="onMoveToStage($event, 0)"
          @stage:opensplit="onOpenSplit($event, 1)"
          @stage:closesplit="onCloseSplit"
          @stage:save="save"
      />

      <Stage
          tabindex="0"
          ref="stage0Ref"
          :key="leftStage.id + leftStage.background"
          :app-state="appState"
          :stage="leftStage"
          :split-index="0"
          :split="split"
          :rteOpen="(rteNode !== null)"
          @stage:focus="onStageFocus(0)"
          @stage:update="updateStage($event,0)"
          @stage:position="stagePosition=$event"
          @stage:nodeselect="onNodeSelect($event)"
          @stage:nodedeselect="onNodeSelect($event)"
          @stage:updatenode="onUpdateNode($event)"
          @stage:addnode="onAddNode($event)"
          @stage:removenode="onRemoveNode($event)"
          @stage:addconnector="onAddConnector($event)"
          @stage:removeconnector="onRemoveConnector($event)"
          @stage:updateconnectoroptions="onUpdateConnectorOptions($event)"
          @stage:leveldown="onLevelDown($event)"
          @stage:levelup="onLevelUp($event)"
          @node:moveright="onMoveToStage($event, 1)"
          @stage:opensplit="onOpenSplit($event, 0)"
          @stage:save="save"
          @stage:openrte="onOpenRTE($event, 0)"
      />


      <div :class="['rte-container', leftStage.style]" v-if="project && split && rteNode !== null">
        <RTE
            ref="rteRef"
            :node="rteNode"
            @rte:close="onCloseSplit"
            :class="leftStage.style"
        />
      </div>

      <OptionsPanel
          v-if="project"
          v-show="!rteNode"
          ref="optionsPanel"
          :active="optionsPanelActive"
          :isEnabled="!rteNode"
          :class="[currentStage?.style, 'noprint']"
          :stage="currentStage"
          :active-stage="project.currentParentNodePointer"
          :split="split"
          :stage-position="stagePosition"
          :node="selectedNode || {}"
          :position="currentStage?.position"
          :nodeSet="project.nodes.filter(n=>n.stageId === currentStage?.id)"
          @update:stageoptions="updateStage($event)"
          @update:nodeoptions="onUpdateNodeOptions($event)"
          @miniview:drag="onMoveStage"
          @center-stage="onCenterStage(project.currentParentNodePointer)"
      />

      <nav id="nav" v-if="project" :class="[currentStage?.style, 'noprint']">
        <!-- Todo: Tour only if user is new  -->
        <fi-button class="button tiny " v-show="!helpEnabled && !project.nodes.length" v-touch:tap="()=>toggleTour()">
          <icon-help/>
          {{$t('help.start_tour')}}
        </fi-button>
        <fi-button class="button tiny" v-show="helpEnabled" v-touch:tap="()=>toggleTour()">
          <icon-help/>
          {{$t('help.end_tour')}}
        </fi-button>

        <!--       Todo: undo / redo stack -->
        <!--        <icon-undo v-touch:tap="undo" :class="canUndo && 'active'"/>-->
        <!--        <icon-redo v-touch:tap="redo" :class="canUndo && 'active'"/>-->

        <icon-save-indicator :spin="appState.busy"/>

        <div class="tag">REV {{ project._rev.split('-')[0] }}</div>
      </nav>

    </div>

  </div>
</template>


<style lang="scss" scoped>
@import "src/styles/variables";

// ---------------------------------------------------------
// Stage (wrapper)
.stagewrapper {
  font-size  : 12px;
  width      : 100vw;
  height     : 100vh;
  position   : fixed;
  top        : 0;
  left       : 0;
  background : white;

  &.wait {
    opacity : 0; // Todo: improve with a simple loader. This might be a split sec only,yet...
    }

  &.split .stage {
    width : calc(50vw - 2px);
    //overflow : hidden;
    &:nth-child(2) {
      margin-left : calc(50vw + 2px);
      }
    }

  .rte-container {
    margin-left : 50vw;
    width       : 50vw;
    height      : 100vh;
    // Gotta overlay stage 1
    position    : fixed;
    z-index     : 500;
    background  : var(--text-color);

    &.dark {
      background : var(--background-color);
      }


    }

  }

optionspanel {
  position : fixed;
  bottom   : 0;
  right    : 0;
  }

// ---------------------------------------------------------
// Nav
// ---------------------------------------------------------
nav {
  position    : fixed;
  top         : 4px;
  right       : 10px;
  display     : flex;
  align-items : center;
  padding     : 5px 15px;
  z-index     : 1000;
  color       : black;

  cursor      : pointer;

  .icon {
    margin  : 0 0.5rem;
    cursor  : pointer;
    height  : 18px;
    width   : 18px;
    opacity : 0.5;

    }

  // Active icons
  &.dark svg {
    fill : white;
    }

  .icon.active {
    opacity : 1;
    }

  }

.middle {
  position   : fixed;
  z-index    : 5;
  width      : 50px;
  top        : 0;
  bottom     : 0;
  left       : calc(50% - 25px);
  background : rgba(0, 0, 0, 0);

  &:hover {
    background : rgba(100, 0, 0, 0.5);
    }
  }

// Saving overlay (full)
//#qd {
//  position        : fixed;
//  flex-direction  : column;
//  left            : 0;
//  top             : 0;
//  right           : 0;
//  bottom          : 0;
//  background      : rgba(0, 0, 0, 0.2);
//  display         : flex;
//  justify-content : center;
//  align-items     : center;
//}


</style>
