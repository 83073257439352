<template>
  <div class="track" v-show="isOpen">
    <slot/>
  </div>
</template>
<script>
import { computed } from "vue";

export default {
  name: 'OptionsSubPanel',
  props: ['open'],
  setup(props, {emit}) {

    const isOpen = computed({
      get: () => {
        return props.open
      },
      set: (val) => emit('toggle', val)
    })


    return {
      isOpen
    }
  }
}
</script>
