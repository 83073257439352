<template>
  <div class="colorPicker">

    <OptionsSubPanel :open="open">
      <ul ref="color">

        <!--        <li class="color" v-for="color in palette" @click="pickColor(color)" :key="color">-->
        <!--          <div :style="`background:${color}`"></div>-->
        <!--        </li>-->
        <!--        <li class="divider"></li>-->
        <li class="color" v-for="pattern in patterns" @click="pickPattern(pattern)" :key="pattern"
            :style="pattern.css"></li>

      </ul>
    </OptionsSubPanel>

    <a class="color" @click="toggle">
      <div class="colorInner" :style="`${appliedColor.background}`"></div>
    </a>
  </div>
</template>
<script>
/* eslint-disable */

import { computed, onMounted, ref } from "vue"
import OptionsSubPanel              from "@/components/OptionsSubPanel";

export default {
  name: 'BgPicker',
  components: {OptionsSubPanel},
  emits: ['update:modelValue', 'colorPick', 'open'],
  props: ['background'],
  setup(props, {emit}) {

    let open = ref(false)
    let color = ref('rgb(255, 255, 255)')
    let picker = ref(null)

    // Palette
    const palette = ref(['rgb(199, 203, 196)', 'rgba(89, 28, 11, 0.7)', '#203647', '#355E3B', '#000000'])

    const patterns = ref([

      {
        css: `background-color: white; background-image: radial-gradient( circle 50% at 48.2% 50%,  rgba(255,255,255,1) 0%, rgb(199, 203, 196, 0.8);`,
        style: "light",
        key: 0
      },
      {
        // dark std
        css: `background-color: #383838; background-size:10px 10px;background-image: repeating-linear-gradient(45deg, #383838 0, #383838 1px, #323232 0, #323232 50%);`,
        style: "dark",
        key: 4
      },
      // Gray
      //      {
      //        css: `background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;
      // background-blend-mode: multiply,multiply;`,
      //        style: "dark"
      //      },
      {
        // Blue rad
        css: `background-color: #5091DD;background: radial-gradient(ellipse at bottom, #5091DD 0%, #030617 100%);`,
        style: "dark",
        key: 1
      },

      {
        // Red(dish)
        css: `background-image: linear-gradient(to top, rgba(89, 28, 11, 1) 0%, rgba(129, 88, 64, 1) 100%);`,
        style: 'dark',
        key: 2
      },
      {
        // blue
        css: `background-image: linear-gradient(to top, #09203f 0%, #537895 100%);`,
        style: 'dark',
        key: 3
      },

      // {
      //   // green
      //   css: `background-image: linear-gradient( 70.5deg,  rgb(68 113 75) 23.5%, #355E3B 91.3% );`,
      //   style: "dark",
      //   key: 4
      // },

      // Patterns
      {
        css: `background-color: #ffffff;background-image: radial-gradient(#a8aad2 0.9px, #ffffff 0.9px);background-size: 28px 28px;`,
        style: 'light',
        key: 5
      },


      {
        css: `background-color: #ffffff;
opacity: 1;
background-size: 6px 6px;
background-image: repeating-linear-gradient(45deg, #dddddd 0, #dddddd 0.6000000000000001px, #ffffff 0, #ffffff 50%);`,
        style: 'light',
        key: 6
      },
      {
        css: `background-color: #e5e5f7;opacity: 1;background-image:  linear-gradient(#cfd4e5 2px, transparent 2px), linear-gradient(90deg, #cfd4e5 2px, transparent 2px), linear-gradient(#cfd4e5 1px, transparent 1px), linear-gradient(90deg, #cfd4e5 1px, #e5e5f7 1px);background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;`,
        style: 'light',
        key: 7
      },
      {
        css: `background-color: #e5e5f7;opacity: 1;background-image:  linear-gradient(#c7c8e3 1px, transparent 1px), linear-gradient(to right, #c7c8e3 1px, #e5e5f7 1px);background-size: 20px 20px;`,
        style: 'light',
        key: 0
      },

      // images
      {
        css: `background-image:url(/backgrounds/chalkboard.jpg);background-size:cover;`,
        style: 'dark'
      }


    ])

    // Color data props
    const appliedColor = computed({
      get: () => {
        return props.background || {background: '', style: 'light'}
      },
      set: (val) => emit('update:modelValue', val),
    });


    function toggle() {
      open.value = !open.value
      if (open.value) emit('open')
    }

    function pickColor(color) {
      emit('colorPick', {background: `background-color:${color}`, style: _lightOrDark(color)})
      open.value = false
    }

    function pickPattern(pattern) {
      emit('colorPick', {background: pattern.css, style: pattern.style})
      open.value = false
    }


    function _lightOrDark(color) {

      // Variables for red, green, blue values
      let r, g, b, hsp;

      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
      } else {

        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
          0.299 * (r * r) +
          0.587 * (g * g) +
          0.114 * (b * b)
      );

      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 127.5) {

        return 'light';
      } else {

        return 'dark';
      }
    }


    onMounted(_ => {
      // Create palette
    })


    return {
      palette,
      patterns,
      picker,
      open,
      color,
      appliedColor,
      toggle,
      pickColor,
      pickPattern,
      close: () => {open.value = false}
    }
  }


}
</script>
<style lang="scss" scoped>
@import "src/styles/variables";
// D.R.Y.!
$defaultBg  : rgba(50, 50, 50, 0.9);
$invertedBg : #eee;

.colorPicker {
  position : relative;
  z-index  : 100;

  .track {
    left  : -100px;
    width : auto;
    @media (max-width : 940px) {
      right : 101px;
    }
  }


  ul {
    margin     : 5px 0 0 0;
    padding    : 0;
    display    : flex;
    flex-wrap  : wrap;
    flex-basis : fit-content;
    min-width  : 192px;
  }

  // Make this universal.
  .color {
    // Dots
    list-style    : none;
    //float         : left;
    border        : 2px solid #aaaaaa;
    margin        : 3px;
    padding       : 2px;

    cursor        : pointer;
    display       : block;
    width         : 31px;
    height        : 31px;
    border-radius : 50%;
    background    : white;

    overflow      : hidden;
    position      : relative;

    div {
      position : absolute;
      left     : -10%;
      top      : -10%;
      width    : 120%;
      height   : 120%;
    }
  }

  li.divider {
    display    : block;
    float      : none;
    width      : 100%;
    height     : 1px;
    background : var(--text-color);
    clear      : both;
    margin     : 5px 0;
  }

}



</style>
