<template>
	<!--	Connectors Layer	-->
	<svg v-if="connectors" :width="svgWidth" :height="svgHeight"
	     :style="{position: 'absolute', top: `${svgTop}px`, left: `${svgLeft}px`}">
		<g v-for="link in connectors"
		   :key="`${link.from}-${link.to}`">
			<path
				:d="computePath(link).path"
				fill="none"
				stroke-width="1"
				stroke-dasharray="4 2"
			/>
			<circle :cx="computePath(link).sx" :cy="computePath(link).sy" r="3"/>
			<circle :cx="computePath(link).ex" :cy="computePath(link).ey" r="3"/>
		</g>
	</svg>
</template>
<script setup>
import { computed, defineProps } from 'vue'

const props = defineProps({
	connectors: {
		type:    Array,
		default: () => []
	},
	nodes:      {
		type:    Array,
		default: () => []
	}
})

const svgWidth = computed(() => boundingBox.value.width || 0);
const svgHeight = computed(() => boundingBox.value.height || 0);
const svgTop = computed(() => boundingBox.value.top || 0);
const svgLeft = computed(() => boundingBox.value.left || 0);

// -------------------------------------------
// Compute the bounding box for the SVG based on nodes

const boundingBox = computed(() => {
	if (!props.nodes) return {top: 0, left: 0, width: 1, height: 1}
	try {
		const minX = Math.min(...props.nodes.map(n => n.position.x));
		const minY = Math.min(...props.nodes.map(n => n.position.y));
		const maxX = Math.max(...props.nodes.map(n => n.position.x + n.position.w / 2));
		const maxY = Math.max(...props.nodes.map(n => n.position.y + n.position.h / 2));

		return {
			top:    minY,
			left:   minX,
			width:  maxX - minX,
			height: maxY - minY,
		}
	} catch (e) {
		return {
			top:    0,
			left:   0,
			width:  120,
			height: 20,
		}
	}
})

/**
 * Create an svg path and set coords for dots.
 * @param link
 * @returns {{path: string, ex: number, ey: number, sx: number, sy: number}|string}
 */
const computePath = (link) => {
	const fromDiv = props.nodes.find(n => n.id === link.from);
	const toDiv = props.nodes.find(n => n.id === link.to);

	if (!fromDiv || !toDiv) return '';

	// Compute the 4 positions for both divs
	const fromPositions = {
		top:    {x: fromDiv.position.x + fromDiv.position.w / 2, y: fromDiv.position.y},
		bottom: {x: fromDiv.position.x + fromDiv.position.w / 2, y: fromDiv.position.y + fromDiv.position.h},
		left:   {x: fromDiv.position.x, y: fromDiv.position.y + fromDiv.position.h / 2},
		right:  {x: fromDiv.position.x + fromDiv.position.w, y: fromDiv.position.y + fromDiv.position.h / 2},
	};

	const toPositions = {
		top:    {x: toDiv.position.x + toDiv.position.w / 2, y: toDiv.position.y},
		bottom: {x: toDiv.position.x + toDiv.position.w / 2, y: toDiv.position.y + toDiv.position.h},
		left:   {x: toDiv.position.x, y: toDiv.position.y + toDiv.position.h / 2},
		right:  {x: toDiv.position.x + toDiv.position.w, y: toDiv.position.y + toDiv.position.h / 2},
	};

	let minDistance = Infinity;
	let start = null, end = null;

	// Find the closest positions
	for (const fromKey in fromPositions) {
		for (const toKey in toPositions) {
			const distance = Math.sqrt(Math.pow(fromPositions[fromKey].x - toPositions[toKey].x, 2) + Math.pow(fromPositions[fromKey].y - toPositions[toKey].y, 2));

			if (distance < minDistance) {
				minDistance = distance;
				start = fromPositions[fromKey];
				end = toPositions[toKey];
			}
		}
	}

	const fromX = start.x - svgLeft.value;
	const fromY = start.y - svgTop.value;
	const toX = end.x - svgLeft.value;
	const toY = end.y - svgTop.value;

	// Curve
	const cp1x = fromX - (fromX - toX) / 2;
	const cp1y = fromY;
	const cp2x = toX + (fromX - toX) / 2;
	const cp2y = toY;

	return {
		path: `M${ fromX } ${ fromY } C ${ cp1x } ${ cp1y }, ${ cp2x } ${ cp2y }, ${ toX } ${ toY }`,
		sx:   fromX,
		sy:   fromY,
		ex:   toX,
		ey:   toY
	}
}


</script>

<style scoped>
svg {
	z-index: -1;
}
/* Todo: come css class must override this. Find and remove */
.light svg path {
	stroke : #333333 !important;
}

.dark svg path {
	stroke : white;
}

svg circle {
	fill : #333 !important;
}

.dark svg circle {
	fill : white;
}
</style>
