<template>
  <div id="f-recorder">

    <div class="controls">
      <!--<pre>-->
      <!--  {{isRecording}} | {{isPlaying}} | {{duration}} |-->
      <!--</pre>-->

      <!--         v-show="!isPlaying && duration <= 0 && !isRecording"  -->
      <icon-button
          v-show="!isPlaying && duration <= 0"
          class="ar-icon ar-icon__sm"
          :name="iconButtonType"
          :class="{
            'ar-icon--rec': isRecording,
            'ar-icon--pulse': isRecording && !isPaused
          }"
          v-touch:tap="toggleRecorder"/>

      <icon-button
          v-show="!isRecording && duration > 0"
          class="ar-icon ar-icon__sm"
          :name="!isPlaying ? 'play' : 'pause'"
          v-touch:tap="togglePlay"/>

      <icon-button
          v-show="isRecording"
          class="ar-icon ar-icon__xs ar-recorder__stop"
          name="stop"
          v-touch:tap="stopRecorder"/>


    </div>

    <div class="waveform" :id="`waveform-${uid}`">

    </div>


    <div class="recorder__current">
      {{ currentTime }}
    </div>

  </div>

</template>
<script>
/* eslint-disable */
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, toRefs } from "vue";
import WaveSurfer                                                                from "wavesurfer.js"
import * as MicrophonePlugin                                                     from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
import getBlobDuration                                                           from "get-blob-duration";
import IconButton                                                                from '@/components/audio/components/icon-button'
import { convertTimeMMSS }                                                       from "@/components/audio/libs/utils";
import { uuid }                                                                  from "vue-uuid";
import { useProjectStore }                                                       from "@/store/project";
import { apiGetAttachment }                                                      from "@/store/api";
import { storeToRefs }                                                           from "pinia";

WaveSurfer.microphone = MicrophonePlugin


export default {
  name: 'fRecorder',
  components: {IconButton},
  props: ['audio', 'node', 'stageId'],
  emits: ['audio:ready', 'audio:recorded'],

  setup(props, {emit}) {

    //
    let uid = uuid.v1()
    let micWs = null
    let waveSurfer = null
    let mediaRecorder = null

    const data = ref(null)

    const projectStore = useProjectStore()
    const {project} = storeToRefs(projectStore)

    // Time
    const maxTime = 120 // max. 2min

    // Data
    let recording = reactive({
      duration: 0,
      isRecording: false,
      isPlaying: false,
      isPaused: false,
      audioLoaded: false
    })

    const currentTime = computed(() => {
      // if (!recording.duration) recording.duration = 0 // mutation!
      let converted = ''
      // Cut recording after maxTime
      if (recording.isRecording && recording.duration >= maxTime) {
        stopRecorder()
      }
      converted = convertTimeMMSS(recording.duration)

      return converted
    })

    // Icon button states
    let iconButtonType = computed(() => {
      return recording.isRecording && recording.isPaused ? 'mic' : recording.isRecording ? 'pause' : 'mic'
    })

    // https://github.com/katspaugh/wavesurfer.js/blob/master/UPGRADE.md
    // https://github.com/katspaugh/wavesurfer.js
    onMounted( () => {
      nextTick(async () => {
        waveSurfer = WaveSurfer.create({
          container: `#waveform-${uid}`,
          scrollParent: true,
          waveColor: '#c00',
          progressColor: '#C99',
          hideScrollbar: true,
          height: 32,
          plugins: [
            // MicrophonePlugin.create({})
            WaveSurfer.microphone.create({
              bufferSize: 4096,
              numberOfInputChannels: 1,
              numberOfOutputChannels: 1,
              constraints: {
                video: false,
                audio: true
              }
            })
          ],
          // backend: 'MediaElement'
        });

        waveSurfer.on('play', () => {
          recording.isPlaying = true
        })

        waveSurfer.on('pause', () => {
          recording.isPaused = true
          recording.isPlaying = false
        })

        waveSurfer.on('finish', () => {
          recording.isPlaying = false
        })

        // Get audio directly from api, if any
        data.value = await apiGetAttachment({docId: project.value._id, attId: `audio_${props.node.id}`})
        if (data.value && waveSurfer) {
          waveSurfer.loadBlob(data.value)
          waveSurfer.on('ready', () => {
            recording.blob = data.value  // working with this in togglePlay
            recording.audioLoaded = true
            recording.duration = waveSurfer.getDuration()
          })
        }

      })
    })

    // Watch for changes of the audio blob.
    // Note: the audio blob will not be loaded when the node is initialised
    // watch(() => props.audio, () => {
    //   // console.log('xx Audio blob prop update ', props.audio, waveSurfer)
    //   console.log('MOUNTED ', props.audio)
    //   if (props.audio && waveSurfer) {
    //     waveSurfer.loadBlob(props.audio)
    //     waveSurfer.on('ready', () => {
    //       recording.blob = props.audio  // working with this in togglePlay
    //       recording.audioLoaded = true
    //       recording.duration = waveSurfer.getDuration()
    //     });
    //   }
    // }, {immediate: true})


    // SPECTRUM: https://codepen.io/Tibixx/pen/XwMQLQ !
    // Siri: https://codepen.io/fgnass/pen/LWeKNq
    // https://codepen.io/davidtorroija/pen/WYaKPq

    /**
     * Recording fn
     */
    function toggleRecorder() {
      // https://wavesurfer-js.org/doc/class/src/plugin/microphone.js~MicrophonePlugin.html
      if (micWs) {
        micWs.destroy()
      }

      recording.duration = 0  // reset  here.

      if (!micWs) {
        micWs = waveSurfer.microphone
        micWs.on('deviceReady', function (stream) {

          // https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder
          let audioChunks = []
          let recordingStart = 0
          mediaRecorder = new MediaRecorder(stream);

          mediaRecorder.ondataavailable = (event) => {
            const now = Date.now()
            recording.duration = Math.ceil((now - recordingStart) / 1000)
            // console.log('DA ', event, event.timecode, recording.duration)

            if (event.data)
              audioChunks.push(event.data);
          }

          mediaRecorder.onstart = () => {
            recordingStart = Date.now()
          }


          mediaRecorder.start(1000) // 1s chunks

          // Stop and get audio blob
          mediaRecorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks, {type: mediaRecorder.mimeType});
            await projectStore.updateNode(props.node, audioBlob, props.stageId)
            // Update self, if still there
            _updateRecord({audioBlob: audioBlob, duration: getBlobDuration(audioBlob)})
            // emit('audio:recorded', audioBlob)
          }
        })

        micWs.on('deviceError', function (code) {
          console.warn('Device error: ' + code);
        })
      }

      if (!recording.isRecording) {
        recording.duration = 0
        recording.isRecording = true
        recording.audioLoaded = false
        micWs.start()
      } else if (recording.isRecording) {
        if (!recording.isPaused) {
          micWs.pause()
          recording.isPaused = true
        } else {
          micWs.play()
          recording.isPaused = false
        }
      }
    }

    /**
     * Stop recording
     */
    async function stopRecorder() {
      if (!recording.isRecording) {
        return
      }
      recording.isRecording = false
      micWs.stopDevice()
      // Destroy mic
      micWs.destroy()
    }

    /**
     * Toggle audio playback
     */
    function togglePlay() {
      if (!waveSurfer.isPlaying() && !recording.audioLoaded) {
        // Load a blob first and wait for ready:
        waveSurfer.loadBlob(recording.blob)
        waveSurfer.on('ready', () => {
          waveSurfer.play()
          recording.audioLoaded = true
        })
      } else {
        waveSurfer.playPause()
      }

    }

    /**
     * Update current record with data
     * Once recording has finished!
     */
    function _updateRecord(audio) {
      // The blob.
      recording.blob = audio.audioBlob
      if (!recording.blob) return
      waveSurfer.loadBlob(recording.blob)
      waveSurfer.on('ready', () => {
        recording.audioLoaded = true
        recording.duration = waveSurfer.getDuration()
      })
    }

    // Clear that stuff
    onBeforeUnmount(() => {
      // Will hopefully remove all events
      waveSurfer.destroy()
    })

    return {
      uid,
      iconButtonType,
      ...toRefs(recording),
      toggleRecorder,
      stopRecorder,
      togglePlay,
      currentTime
    }
  }

}
</script>
<style scoped lang="scss">
#f-recorder {
  position        : absolute;
  font-size       : 11px;
  width           : 310px;
  border-radius   : 4px;
  display         : flex;
  flex-direction  : row;
  color           : black;
  box-shadow      : rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  background      : rgba(255, 255, 255, 0.5);
  backdrop-filter : blur(4px);

  > div {
    margin      : 5px;
    display     : flex;
    align-items : center;

  }

  .recorder__current {
    font-family  : one;
    margin-right : 10px;
    //width        : 120px;
  }

  .waveform {
    display : block;
    width   : 100%;
    height  : 32px;
  }
}
</style>
