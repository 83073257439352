<template>
  <!--suppress HtmlUnknownAttribute -->
  <VueDragResize
      ref="me"
      v-if="component && stage"
      :id="component.id"
      :class="[`node`, isCollided && 'collided', active && 'active', editable ? 'editable' : '', component.type]"
      :is-active="active"
      v-model:x="position.x"
      v-model:y="position.y"
      v-model:w="position.w"
      v-model:h="position.h"
      :sticks="component.type !== 'audio' ? ['br'] : []"
      :stick-size="15"
      :is-draggable="!editable"
      :parent-scale-x="stage.position.scale"
      :parent-scale-y="stage.position.scale"
      :preventActiveBehavior="false"
      @focusout="lockNode"
      @focusin="$emit('node:focus')"
      @mousedown="onStart"
      @touchstart="onStart"
      @dragging="onDrag"
      @mouseup="onDragEnd"
      @touchend="onDragEnd"
      @resizestop="checkSize"
      v-touch:hold="editMode"
      @dblclick="editMode"
      @activated="toggleActive(true)"
      @deactivated="toggleActive(false)"
  >

<!--        <pre class="debug">-->
<!--   {{component.position.x}} | {{component.position.y}}-->
<!--        </pre>-->

    <div name="node" ref="deco" class="deco">
      <div v-if="!data.type || data.type === 'default'"
           ref="ta"
           :class="['inner',editable ? `editable` : `locked`, (component.content === '...' || component.content === '') && 'empty']"
           :contenteditable="true"
           role="textbox"
           @keyup="updateContent"
           @focusout="lockNode"
           v-html="component.content"
      >
      </div>
      <Sketchpad ref="sketchPad" v-if="data.type === 'sketch'"
                 :data="attachment"
                 :locked="!editable"
                 :bg-style="backgroundColor"
                 :scale="stage.position.scale"
                 @dataUpdate="sketchPadUpdate"
                 style="position:relative; min-width: 200px;min-height:200px;"/>
      <f-recorder ref="audioComp" v-if="data.type === 'audio'"
                  :node="component"
                  :splitIndex="stage.id"
                  :audio="attachment"
      />
    </div>

    <div class="nav" v-show="appState.nodeNavEnabled">

      <span class="icon trash"
            v-show="data.type !== 'audio' || (data.type === 'audio' && audioComp && !audioComp.isRecording)"
            @click="$emit('node:remove',{component: component})"
            @touchend="$emit('node:remove',{component: component})">
        <icon-trash/>
      </span>

      <span v-if="data.type !== 'sketch'"
            v-show="data.type !== 'sketch' && data.type !== 'audio'"
            :class="['icon', childNum ? 'hasChildren' : '']"
            @click="$emit('node:levelDown', {component: component})"
            @touchend="$emit('node:levelDown', {component: component})">
          <span v-show="!childNum || childNum === 0"><icon-layers/></span>
          <icon-layers-f v-show="childNum > 0"/>
      </span>

      <span v-show="splitIndex < 1 && data.type !== 'audio' && data.type !== 'sketch'"
            @click="$emit('node:openRTE', component)"
            @touchend="$emit('node:openRTE', component)"
            :class="['icon', (component?.html) && 'hasChildren'] "
      >
        <icon-text/>
      </span>

      <span v-show="splitIndex < 1 && data.type !== 'audio' && data.type !== 'sketch'"
            class="icon split right"
            @click="$emit('node:openSplit', component)"
            @touchend="$emit('node:openSplit', component)">
        <icon-split-right/>
      </span>


    </div>

    <div class="nav is-right">
    <span v-if="allowedMoveRight" class="icon to-stage-1">
         <icon-arrow-right @click="$emit('node:moveright', {component: component})"
                           @touchend="$emit('moveright', {component: component})"/>
      </span>
    </div>
    <div class="nav is-left">
    <span v-if="allowedMoveLeft" class="icon to-stage-0">
         <icon-arrow-left @click="$emit('node:moveleft', {component: component})"
                          @touchend="$emit('moveleft', {component: component})"/>
    </span>
    </div>

  </VueDragResize>
</template>

<script>
/* eslint-disable */

/**
 * A node
 *
 * This handles the position, style and drag events
 * This class is using dragresize.vue -- there is some cleanup to do.
 *
 */

import VueDragResize                                                                 from '@/components/dragresize/dragresize'
import { uuid }                                                                      from "vue-uuid";
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { nodeModel, nodeOptions }                                                    from "@/models";
import linkifyHtml                                                                   from "linkify-html"
import Sketchpad                                                                     from "@/components/Sketchpad";
import FRecorder                                                                     from "@/components/audio/fRecorder";
import { calcColorContrast }                                                         from "@/utils";
import { appStateStore }                                                             from "@/store/appState";
import { useProjectStore }                                                           from "@/store/project";
import { storeToRefs }                                                               from "pinia";
import { apiGetAttachment }                                                          from "@/store/api";

export default {
  name: 'Node',
  components: {
    FRecorder,
    Sketchpad,
    VueDragResize
  },
  data() {
    return {
      uuid: uuid.v1()
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return nodeModel
      }
    },
    stage: {
      type: Object,
      default: () => {}
    },
    rteOpen: {
      type: Boolean,
      default: false
    },
    scale: {
      type: Number,
      default: 1
    },
    childNum: { // should be "hasOwnStage" or so.
      type: Number,
      default: 0
    },
    split: {
      type: Boolean,
      default: false
    },
    splitIndex: {
      type: Number,   // this is not the actual stage ID - it's the active stage 0 | 1
      default: 0
    },
    allowedMoveRight: {
      type: Boolean,
      default: true
    },
    allowedMoveLeft: {
      type: Boolean,
      default: true
    }

  },
  emits: [
    'node:update',
    'node:remove',
    'node:active',
    'node:checkcollission',
    'node:levelDown',
    'node:openSplit',
    'node:openRTE',
    'node:focus',
    'node:edit',
    'node:moveleft',
    'node:moveright'
  ],

  setup(props, {emit}) {
    // element
    const me = ref(null)
    // Decoration wrapper
    const deco = ref(null)
    // Textarea
    const ta = ref(null)
    // Sketchpad
    const sketchPad = ref(null)
    // AudioRecPlayer
    const audioComp = ref(null)
    // Attachment data
    const attachment = ref(null)
    // Editable State
    let editable = ref(true)
    // Active state (if the node is selected and focused)
    let active = ref(false)
    // Collided (passive = the node got hit with another)
    let isCollided = ref(false)
    // Hack: ???????
    let isMoving = ref(false)
    // InitialPosition is saved
    let initialPosition = ref(null)
    // Node position
    // Hacked ~hotfix~~~ uuuuhhhh caliente!
    // if (!props.data.position) props.data.position = nodeModel.position // jämmerlich

    // One-off setting of position.
    // This avoids re-upate of position due to prop change.
    // let position = ref({
    //   x: props.data.position.x || 0,
    //   y: props.data.position.y || 0,
    //   w: props.data.position.w || 120,
    //   h: props.data.position.h || 20
    // })

    const position = computed({
      get:()=>props.data.position,
      set:()=>{
        //console.info('node pos change', val)
        // this will not and must not kick. We emit our own events.
      }
    })

    const backgroundColor = ref('white')  // SketchPad needs this

    // AppState
    const appState = appStateStore()

    const projectStore = useProjectStore()
    const {project} = storeToRefs(projectStore)

    // main component data
    const component = computed({
      get: () => props.data,
      set: (val) => {} //emit('update:modelValue', val)    // needed?
    })

    // Parsed style / options of node
    let optionsParsed = ref(null)
    // Watch data/options and apply
    watch(
        () => props.data.options,
        (nv, ov) => {
          nextTick(() => {
            if (nv && nv !== ov) {
              applyOptions()
            }
          })
        }, {immediate: true}
    )
    // Watch stage/options and apply [change colours]
    watch(
        () => props.stage,
        (nv, ov) => {
          nextTick(() => {
            if (nv && nv !== ov) {
              applyOptions()
            }
          })
        }, {immediate: true}
    )

    // --------------------------------------------------------
    // Mounted node

    onBeforeMount(() => {
      updateContent()
    })

    onMounted(() => {
      nextTick(async () => {
        // Hook up paste event
        if (ta.value)
          ta.value.addEventListener("paste", _pastePlain)
        // New nodes get focus (text | sketch)
        if (component.value?._new) {
          // Use internal state of this instead.
          editMode(null)
          // Activate
          toggleActive(true, null)
          // Remove flag
          delete component.value._new
        }
      })
    })

    onBeforeUnmount(() => {
      // Lock and trigger update (data)
      lockNode()
      // console.log('Did you delete me?')
      me.value = null
      if (ta.value)
        ta.value.removeEventListener('paste', _pastePlain)
    })

    // --------------------------------------------------------
    // Methods
    // --------------------------------------------------------

    // Update node contents
    async function updateContent() {
      // Reload Attachments
      if (component.value.type === 'default') {
        // Open right up and select all, if no content.
        if (ta.value && component.value._new) {
          ta.value.focus()  // focus on newly created
        }
      } else if (component.value.type === 'sketch') {
        // const att = project.value._attachments[`sketch_${component.value.id}`]
        attachment.value = await apiGetAttachment({docId: project.value._id, attId: `sketch_${component.value.id}`}, true)
        adjustSizeForSketch(true)
      } else if (component.value.type === 'audio') {
        attachment.value = await apiGetAttachment({docId: project.value._id, attId: `audio_${component.value.id}`})
        adjustSizeForAudio(true)
      }
      checkSize({
        left: component.value.position.x,
        top: component.value.position.y,
        width: component.value.position.w,
        height: component.value.position.h
      })

    }

    /**
     * Toggle a node to active state or not
     * An ACTIVE node has focus for text content and is resizeable
     * A SELECTED node is unfocused and deselected but still available to
     * receive options updates (color, size, border, ...)
     */
    function toggleActive(to, event) {
      if (to) {
        active.value = true;
        emit('node:active', {component: component.value, active: true})
      } else {
        active.value = false;
        lockNode();
      }
    }


    // Apply current / new options from format panel
    function applyOptions() {
      // console.log('Applying ', props.data.options)
      if (!props.data.options || !me.value) return


      let opts = {...nodeOptions, ...props.data.options},
          ref = deco.value

      if (opts.fontFamily)
        ref.style.fontFamily = opts.fontFamily
      // Alignment
      if (opts.align)
        ref.style.textAlign = opts.align
      // FontSize
      if (opts.fontSize)
        ref.style.fontSize = opts.fontSize + 'px'
      // FontColor
      if (opts.color)
        ref.style.color = opts.color
      if (opts.color === '')
        ref.style.color = 'inherit'
      // Border
      if (opts.border)
        ref.style.border = opts.border
      // BorderRadius
      if (opts.borderRadius)
        ref.style.borderRadius = opts.borderRadius + 'px'
      // BorderColor
      if (opts.borderColor)
        ref.style.borderColor = opts.borderColor
      // Background color
      if (opts.backgroundColor) {
        ref.style.backgroundColor = opts.backgroundColor
        // Set background color globally (for sketchPad, if any)
        backgroundColor.value = calcColorContrast(opts.backgroundColor)
      }
      if (opts.backgroundColor === '') {
        ref.style.backgroundColor = 'transparent'
        // light or dark. Fallback to stage bg color
        backgroundColor.value = calcColorContrast(props.stage?.style || 'light')
      }
      // console.log('NODE - stage watch', opts)

      // Update frame
      nextTick(_ => {
        checkSize()
      })
    }

    // /**
    //  * Helper for cursor position
    //  * https://stackoverflow.com/questions/6249095/how-to-set-caretcursor-position-in-contenteditable-element-div
    //  * https://www.vishalon.net/blog/javascript-getting-and-setting-caret-position-in-textarea
    //  */
    function setCaret(el) {
      let range, selection;
      if (document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
      {
        range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(el);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection();//get the selection object (allows you to change selection)
        selection.removeAllRanges();//remove any selections already made
        selection.addRange(range);//make the range you have just created the visible selection
      } else if (document.selection)//IE 8 and lower
      {
        range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(el);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        range.select();//Select the range (make it the visible selection
      }
    }

    /**
     * Set editable,
     * select all text initially (when node is new)
     * @param e
     */
    function editMode(e) {

      if (e) {
        e.preventDefault()
        e.stopImmediatePropagation()
      }
      if (editable.value) return

      // add 'contenteditable'
      editable.value = true

      // Let editor know to lock movements
      emit('node:edit', {component: component.value, editable: true})

      // ----- sketch
      if (component.value.type === 'sketch') {
        adjustSizeForSketch()
        return
      } else if (component.value.type === 'audio') {
        adjustSizeForAudio()
        return
      }

      // ----- text node only
      // collapse selection to the end || older: https://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity/3866442#3866442
      // if (ta.value.innerHTML.length > 9) {
      try {
        document.getSelection().collapseToEnd();
      } catch {
        // console.warn('no sel.')
      }

      // Focus text
      setTimeout(function () {
        if (!ta.value) return
        ta.value.focus()  // focus on newly created

        if (ta.value.innerText.length <= 3) {
          // focus on text node and select all (if content === '...')
          let sel, range;
          if (window.getSelection && document.createRange) {
            range = document.createRange();
            range.selectNodeContents(ta.value);
            sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
          } else if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(ta.value);
            range.select();
          }
        } else {
          // Just move cursor to end
          setCaret(ta.value)
        }


      }, 10)

    }

    /**
     * Lock node, unFocus, just draggable
     * This will create an UPDATE event for text / sketch nodes
     */
    async function lockNode() {
      if (!editable.value) return

      // Check type and emit content accordingly
      if (component.value.type === 'default' && ta.value) {
        ta.value.blur()
        if (editable.value)
          if (window.getSelection) {
            window.getSelection().removeAllRanges();
          } else if (document.selection) {
            document.selection.empty();
          }

        // Get html content
        let content = ta.value.innerHTML
        // Parse and apply html links / a
        content = linkifyHtml(content)
        component.content = content // update the node content again
        // Emit update
        emit('node:update', {id: component.value.id, content: content})
        // await projectStore.updateNode({...component.value, ...{content: content}}, null, props.stage.id)
      } else if (component.value.type === 'sketch' && sketchPad.value) {
        // emit('updateNode', {id: component.value.id, hasAttachment: true, attachment: sketchPad.value.getData()})
        // Todo: emit instead
        await projectStore.updateNode(component.value, sketchPad.value.getData(), props.stage.id)
      }
      // else if (component.value.type === 'audio')
      // Audio is set when recording has stopped, not on blur.
      editable.value = false
    }

    /**
     * Node size for sketchpad
     */
    function adjustSizeForSketch(emits = false) {
      if (!me.value || !sketchPad.value) return

      const nodeSize = me.value.rect
      const canvasSize = sketchPad.value.getSize()

      // If node size < canvas
      if (nodeSize.width < canvasSize.width || nodeSize.height < canvasSize.height) {
        position.value.w = canvasSize.width
        position.value.h = canvasSize.height
      } else {
        // If canvas < node size
        sketchPad.value.setSize({w: nodeSize.width, h: nodeSize.height})
      }
      if (emits) emit('node:update', {component: {position: me.value.rect}})
    }

    /**
     * Node size for audio - should be fixed (310px)
     */
    function adjustSizeForAudio(emits = false) {
      nextTick(() => {
        if (audioComp.value?.$el) {
          const rect = audioComp.value?.$el.getBoundingClientRect()
          position.value.w = rect.width
          position.value.h = rect.height
          if (emits) emit('node:update', {component: {position: me.value.rect}})
        }
      })
    }


    /**
     * Node size for text
     * This reacts to the height of the inner editable div (ta)
     */
    function adjustSizeForText(emits = false) {
      // Only for default, text node.
      if (!component.value.position || !ta.value) return // sometimes...
      const newRect = me.value.rect
      // Try to adjust height and width to maximum inner size (textarea) - snap back
      if (newRect && component.value.position.w !== newRect.width || component.value.position.w !== newRect.height) {
        nextTick(() => {
          if (ta.value) {
            let newSize = {
              ...newRect, ...{
                height: ta.value.getBoundingClientRect().height,
                width: ta.value.getBoundingClientRect().width
              }
            }
            // if (newSize.width > component.value.position.w)  component.value.position.w= newSize.width
            if (newSize.width < 80) position.value.w = 80
            // component.value.position.h = newSize.height
            position.value.h = newSize.height
            if (emits) emit('node:update', {component: {position: me.value.rect}})
          }
        })
      } else if (emits)
        nextTick(() => {
          emit('node:update', {component: {...component.value}, rect: me.value.rect})
        })
    }

    // Adjust size of wrapper to inner div (text lines)
    function checkSize(emits = false) {
      // Check types :
      if (component.value.type === 'sketch') {
        adjustSizeForSketch(emits)
      } else if (component.value.type === 'audio')
        adjustSizeForAudio()
      else
        adjustSizeForText(emits)
    }

    /**
     * Paste text
     * @param e
     * @private
     */
    function _pastePlain(e) {
      // cancel paste
      e.preventDefault()
      // get text representation of clipboard
      const text = (e.originalEvent || e).clipboardData.getData('text/plain')
      // Todo: insert text manually @deprecated
      document.execCommand("insertHTML", false, text)
    }

    /**
     * @deprecated
     * Emit update event when sketchpad data has changed
     * There is no event when user finishes his art. This will only trigger on erase.
     * We use the @focusout method instead.
     */
    function sketchPadUpdate() {
      console.warn('@deprecated: SketchpadUpdate', sketchPad.value.getData())
    }

    // --------------------------------------------------------
    // Public
    // --------------------------------------------------------

    // If main class detects a collision, it will tell this node
    // that it got hit with another one = PASSIVE
    function setCollision(payload) {
      isCollided.value = payload
    }

    // Return the node position
    function getPosition() {
      if (!me.value) return // will only happen in dev mode.
      // Current DOM position from VueDraggable class
      return {x: me.value.x, y: me.value.y, w: me.value.w, h: me.value.h}
    }

    // Snap the node back to the position
    // before dragging = ACTIVE
    function snapBack() {
      // A bit shitty, as the underlying class updates each value separately
      setTimeout(_ => {
        position.value.x = initialPosition.value?.x
      }, 10)
      setTimeout(_ => {
        position.value.y = initialPosition.value?.y
      }, 20)
    }

    // Started dragging
    function onStart(e) {
      // Intercept links with SHIFT
      // Todo: how about opening links on touch?
      if (e.target.getAttribute('href') && e.shiftKey) {
        e.preventDefault()
        e.stopImmediatePropagation()
        window.open(e.target.getAttribute('href'))  // Todo: in-app-browser
        return false
      }
      // Store initial position
      initialPosition.value = getPosition()
      isMoving.value = true
    }

    // While dragging
    function onDrag(e) {
      // check this nonsense:
      if (editable.value || !isMoving.value || !me.value) {
        return
      }
      // Try to get the stage target
      // console.log('NPOS -> ', e, document.getElementsByName('editor-0')[0].getBoundingClientRect())

      // Check if we need to move multiple
      // const multiple = (Array.prototype.slice.call(document.getElementsByClassName('node active')).length > 1)
      // console.log('MULTI ', multiple)

      // Check collision
      emit('node:checkcollission', {component: {...component.value}, rect: me.value.rect, dragging: true})
    }

    /**
     * Drag ended
     * Keep position OR
     * when collided, emit event and reset position
     */
    function onDragEnd() {
      if (editable.value || !me.value) {
        return
      }
      // ----------------
      // detect collision

      emit('node:checkcollission', {
        component: component.value,
        rect: me.value.rect,
        dragging: false
      })

      // delete component.value._initialPosition  // Hack

      isMoving.value = false
    }


    return {
      appState,
      me,
      deco,
      ta,
      audioComp,
      sketchPad,
      attachment,
      component,
      active,
      editable,
      isMoving,
      onStart,
      onDrag,
      onDragEnd,
      toggleActive,
      updateContent,
      sketchPadUpdate,
      checkSize,
      editMode,
      lockNode,
      isCollided,
      initialPosition,
      optionsParsed,
      backgroundColor,
      // Public
      setCollision,
      getPosition,
      snapBack,
      position
    };
  },


}
</script>

<style lang="scss">
@import "src/styles/variables";

// Default item
.node {
  z-index   : 1;
  position  : absolute;
  font-size : 12.5px;

  .deco {
    position    : relative;
    width       : 100%;
    height      : 100%;
    display     : flex;
    align-items : center;
    border      : 1px solid transparent;
  }

  .inner {
    display    : table-cell;
    cursor     : pointer;
    width      : 100%;
    height     : auto;
    //min-height : 100%;
    padding    : 0.6rem; //0.35rem 0.35rem 0.36rem 0.35rem;
    background : transparent;

    &:focus {
      //outline : none;
    }

    &.locked {
      pointer-events : none;
    }

    &.editable {
      cursor              : text;
      -webkit-user-select : text;
      -moz-user-select    : text;
      -ms-user-select     : text;
      user-select         : text;
      -webkit-user-modify : read-write;
    }

    // Mark empty nodes, so a user can find them again...
    &.empty {
      border : 1px dotted;
    }
  }

  // Over state
  &.over {
    box-shadow : 0 0 10px #75b0ce;
  }

  // selected state (multiselect)
  //&.selected {
  //  box-shadow : 0px 0px 10px #5a0b46;
  //  }

  // Active state
  &.active, &:hover {
    z-index         : 5 !important;
    background      : rgba(255, 255, 255, 0.05);
    backdrop-filter : blur(4px);

    .deco {
      box-shadow : rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    }

    //
    .inner.empty {
      border : none;
    }
  }

  // Another on hit this one....
  &.collided {
    box-shadow : rgba(17, 17, 26, 0.1) 0 8px 24px, rgba(17, 17, 26, 0.1) 0 16px 56px, rgba(17, 17, 26, 0.1) 0 24px 80px;
  }

  // --------------------------
  // nav
  .nav {
    display         : flex;
    position        : absolute;
    bottom          : -30px;
    width           : 100%;
    z-index         : 5;

    text-align      : left;
    padding-right   : 10px;
    justify-content : flex-end;
    align-items     : flex-end;

    &.right {
      padding        : 0;
      bottom         : 20px;
      right          : -15px;
      z-index        : 1000;
      text-align     : left;
      flex-direction : column;
    }

    span.icon {
      // Color
      color         : var(--primary-color);
      border        : 1px solid var(--primary-color);
      background    : rgba(255, 255, 255, 0.8);
      border-radius : 50%;
      line-height   : 1;
      width         : 26px;
      height        : 26px;
      text-align    : center;
      padding       : 3px;
      font-size     : 18px;
      margin-right  : 10px;
      //margin-bottom : 10px;
      transition    : opacity 420ms;
      cursor        : pointer;

      // If NO children then hide the layer icon
      &:not(.hasChildren) {
        opacity        : 0;
        pointer-events : none;
      }

      &.trash {
        //margin-right : 20px;
        //border-color : var(--highlight-color);
        svg {
          //fill: var(--highlight-color);
        }

        //background: rgba(200,20,0,0.8);
      }

      &.split {
        //&.right {
        //  opacity  : 1;
        //  position : absolute;
        //  top      : 0;
        //  right    : -20px;
        //}
      }

      // Color [light bg]
      svg {
        width  : 100%;
        height : 100%;
        fill   : var(--primary-color);
      }

      // Dark
      .stage.dark & {
        color      : var(--text-color);
        border     : 1px solid white;
        background : rgba(0, 0, 0, 0.5);

        svg {
          fill : var(--text-color);
        }
      }
    }

    svg {
      height       : 13px;
      transition   : opacity 280ms ease-in-out;
      margin-right : 10px;
    }
  }

  // Navigation on hover || active state
  &:hover, &.active {
    .nav {
      //display : flex;
      span.icon {
        pointer-events : auto;
        opacity        : 1;
      }
    }
  }

  // --------------------------
  // Special: fake links
  .link {
    border          : 1px solid;
    text-decoration : underline;
  }

  // ======== node types =======
  // SketchPad type
  &.sketch.editable {
    cursor : crosshair;
  }

  &.audio {
    .content-container {
      width : 300px;
    }
  }

  // --------------------------
  // well...
  .debug {
    position  : absolute;
    top       : -5px;
    font-size : 9px;
  }
}

// Left nav
.nav.is-left {
  position : absolute;
  top      : auto;
  right    : auto;
  left     : -17.5%;
  bottom   : 25%;
  width    : auto;
}

// Right nav
.nav.is-right {
  position : absolute;
  top      : auto;
  right    : -25%;
  bottom   : 25%;
  width    : auto;
}

// --------------------------
// Fake Text Area
[contenteditable] {
  -webkit-user-select : text;
  user-select         : text;
}

// ==========================
// Light or dark background?
.node {
  color : var(--primary-color);

  // --------------------
  // Links inherit color
  a, a:link {
    color           : inherit !important;
    text-decoration : underline;
    pointer-events  : auto; // ctrl + click should open
  }
}

// Change to auto-white if dark stage
.stage.dark .node {
  color : var(--text-color);
}

// end color mode ==========================


</style>
