<script>
import RTE                                               from "@/components/RTE";
import { useProjectStore }                               from "@/store/project";
import { storeToRefs }                                   from "pinia";
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useRoute }                                      from "vue-router";
import { VueDraggableNext as draggable }                 from "vue-draggable-next";
import IconButton                                        from "../components/audio/components/icon-button";
import FiButton                                          from "@/components/fiButton";
import FiIcon                                            from "@/components/fiIcon";

/* eslint-disable */
export default {
  components: {FiIcon, FiButton, IconButton, RTE, draggable},
  setup() {
    const route = useRoute()
    const projectStore = useProjectStore()
    const {project} = storeToRefs(projectStore)
    const selectedNode = ref(null)
    const editor = ref(null)
    const groupByStage = ref(true)

    /**
     * Get nodes from all stages of project
     * @type {WritableComputedRef<unknown>}
     */
    const sortedNodes = computed({
      get: () => {
        if (!project.value) return []
        // Flatten nodes, mixin their stage Id
        // replace html?
        // allNodes = allNodes.filter(n => {if (n.type === 'default') return {...n, ...{content: n.content.replace(/(<([^>]+)>)/gi, "")}}})
        // Just the ones with HTML content / rte content
        let allNodes = project.value.nodes.filter(n => n.type === 'default' && n.html)
        // Order them
        return allNodes.sort((a, b) => a?.order - b?.order)
      },
      set: (val) => {
        val.map((v, i) => {
          project.value.nodes = project.value.nodes.map(n => {
            if (n.id === v.id) n.order = i
            return n
          })
        })
        projectStore.updateProject(project.value)
      }
    })

    /**
     * Sort stages, if mode requests it
     * @type {WritableComputedRef<unknown>}
     */
    const sortedStages = computed({
      get: () => {
        if (!project.value) return []
        let stages = [...project.value.stages.sort((a, b) => a?.order - b?.order)]
        // NOTE: This is a temporary mixin of nodes to stages:
        stages = stages.filter(s => {
          s.nodes = [...project.value.nodes.filter(n => n.stageId === s.id && n.html).sort((a, b) => a?.order - b?.order)]
          return s.nodes.length ? s : null
        })
        return stages

      },
      set: async (val) => {
        val.map((v, i) => {
          project.value.stages = project.value.stages.map(s => {
            if (s && s.id === v.id) s.order = i
            return s
          })
        })
        await projectStore.updateProject(project.value)
        // If manuscript is open, update it
        if (selectedNode.value?.id === null) {
          selectedNode.value = completeHtml.value
        }
      }
    })

    /**
     * Order nodes within a stage.
     * Gotta intrude into the dom a bit
     * @param stage
     */
    const updateNodesInStage = async (stage) => {
      // const stageId = stage.originalEvent.srcElement.getAttribute('data-stageid')
      const container = stage.originalEvent.srcElement.closest('.sNodes')
      // let sortArr = []
      container.querySelectorAll('div > p').forEach((n, i) => {
        let nodeId = n.getAttribute('data-id')
        project.value.nodes = [...project.value.nodes.map(n => {
          if (n.id === nodeId) n.order = i
          return n
        })]
      })

      //
      await projectStore.updateProject(project.value)
      // If manuscript is open, update it
      if (selectedNode.value?.id === null) {
        selectedNode.value = completeHtml.value
      }
    }

    /**
     * Select a node and update editor with .html
     * @param node
     */
    const select = async (node) => {
      // Get editor value before change
      if (editor.value) {
        const n = await editor.value.getData()
        onRteData(n)
      }
      //
      selectedNode.value = node
    }

    /**
     * RTE sent data, update store
     * @param data
     */
    const onRteData = async (data) => {
      // See: We cannot update the node simply, because we don't have a current stage.
      await projectStore.updateNode(data, null, data.stageId)
      await projectStore.putToDb()
      // Refresh from store again. (if we're still en route)
      if (route.params.id)
        await projectStore.getProject(route.params.id)
    }

    /**
     * If toggled, the content of a node is either in or not in the manuscript
     * @param node
     */
    const toggleNodeInclude = (node) => {
      if (!node.htmlNodeInclude) node.htmlNodeInclude = false   // new model may not have the prop.
      node.htmlNodeInclude = !node.htmlNodeInclude
      projectStore.updateNode(node)
      // Todo: Rerender manuscript, if selected node is MANUSCRIPT.

    }

    // -------- Manuscript & RTF

    /**
     * Create a full block content object for rte (all nodes in order)
     * Todo: don't use an existing node to spam it with the new content. AND: changing a node.prop will not update the manuscript view!
     * @returns {null|{html: (*&{blocks: []}), id: null, content: string}}
     */
    const completeHtml = computed(() => {
      if (!sortedNodes.value || !sortedNodes.value.length) return null
      const delimiter = {
        type: "delimiter",
        data: {}
      }
      let blocks = [], prevNode = null
      if (groupByStage.value) {
        let fnode = null

        sortedStages.value.forEach(s => {
          s.nodes.filter((n, i) => {
            if (n.htmlNodeInclude) {
              if (!fnode) fnode = n  // keep first node, because it will have the FAKE content summarised
              // if (n.html?.blocks) n.html = convertDataToHtml(n.html.blocks)

              if (n !== prevNode && i > 0) blocks.push(
                  {
                    "type": "paragraph",
                    "attrs": {"textAlign": "center"},
                    "content": [
                      {
                        "type": "text",
                        "text": "* * *"
                      }
                    ]
                  })

              if (n?.html.content)
                n?.html.content.map(b => {blocks.push(b)})
              prevNode = n
              return n
            }
          })
        })
        if (!fnode) return null
        const html = {...fnode.html, ...{content: blocks}}
        return {id: null, content: "Manuscript (read only)", html: html}  // return as node instance
      } else {
        sortedNodes.value.forEach(n => {
          if (n !== prevNode && n.htmlNodeInclude) blocks.push(delimiter)
          n.html.blocks.map(b => {blocks.push(b)})
          prevNode = n
        })
        const html = {...sortedNodes.value[0].html, ...{blocks: blocks}}
        return {id: -1, content: "Manuscript", html: html}  // return as node instance
      }
    })

    /**
     * Create HTML from editor block
     * @param blocks
     * @returns {string}
     */
    const convertDataToHtml = () => {
      return editor.value.getHTML();
    }

    /**
     * RTF converter
     * TODO: add unicode support for
     * @returns {string|null}
     */
    const exportRtf = () => {
      editor.value.setData(completeHtml.value.html)
      // let html = convertDataToHtml()
      // return htmlToRtf(html)
      let filename = project.value.title

      let preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      let postHtml = "</body></html>";
      let html = preHtml+convertDataToHtml()+postHtml;

      let blob = new Blob(['\ufeff', html], {
        type: 'application/msword'
      });

      // Specify link url
      let url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

      // Specify file name
      filename = filename?filename+'.doc':'document.doc';

      // Create download link element
      let downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if(navigator.msSaveOrOpenBlob ){
        navigator.msSaveOrOpenBlob(blob, filename);
      }else{
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);

    }

    /**
     * Trigger download of rtf
     */
    function download() {
      const blob = new Blob([exportRtf()], {type: "application/rtf;charset=utf-8"});
      const dataStr = window.URL.createObjectURL(blob);
      const dlAnchorElem = document.getElementById('downloadAnchorElem');
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", `Manuscript.rtf`);
      dlAnchorElem.click();
    }

    onBeforeMount(() => {
      if (!route.params.id) {
        throw new Error('This project does not exist. Create?')
      } else
        projectStore.getProject(route.params.id)
    })

    onBeforeUnmount(async () => {
      // save last changes
      const n = await editor.value.getData()
      onRteData(n)
    })

    return {
      project,
      editor,
      sortedStages,
      sortedNodes,
      selectedNode,
      groupByStage,
      updateNodesInStage,
      convertDataToHtml,
      toggleNodeInclude,
      download,
      completeHtml,
      onRteData,
      select
    }
  }
}

</script>

<template>
  <div class="manuscript">
    <div class="nodes">
      <div>
        <div class="message"><strong>This is an experimental feature.</strong> You can edit and arrange all your node contents here and export a manuscript.</div>

        <div class="flex title main">
          <h5 v-if="project">{{ project?.title }}</h5>
          <div>
            <fi-icon :label="'manuscript'" v-if="sortedNodes && completeHtml" v-touch="()=>select(completeHtml)" class="control">
              <icon-book-f/>
            </fi-icon>
            <fi-icon :label="'download'" v-touch="()=>download()">
              <icon-download/>
            </fi-icon>
            <fi-icon :label="'close'" v-touch="()=>$router.push('/projects')" class="control close">
              <icon-close/>
            </fi-icon>

          </div>
        </div>


        <!--        <div v-if="sortedNodes && completeHtml" v-touch="()=>select(completeHtml)" :class="selectedNode?.id === -1 && 'active'">-->
        <!--          <div class="title main">-->
        <!--            <div>{{ completeHtml.content }}</div>-->
        <!--            <icon-download v-touch="()=>download()"/>-->
        <!--          </div>-->
        <!--        </div>-->

        <div v-if="groupByStage">
          <draggable class="dragArea mNodes" v-model="sortedStages" item-key="id">
            <div v-for="stage in sortedStages" :key="stage.id">
              <h5 class="title chapter dg" v-if="stage.id !== 0">{{ stage.title || $t('chapter') }}</h5>
              <h5 class="title chapter dg" v-if="stage.id === 0">{{ project.title }}</h5>
              <draggable class="dragArea sNodes" @sort="updateNodesInStage" item-key="id">
                <div class="node--item" v-for="node in stage.nodes" :key="node.id+node.time">
                  <p :class="['title child dg', selectedNode?.id === node.id && 'active']" :data-stageid="stage.id" :data-id="node?.id" v-html="node?.content" v-touch.passive="()=>select(node)"></p>
                  <label class="switch">
                    <input type="checkbox" :checked="node?.htmlNodeInclude" @click="toggleNodeInclude(node)">
                    <span class="slider round"></span>
                  </label>
                </div>
              </draggable>
            </div>
          </draggable>
        </div>

        <div v-if="!groupByStage">
          <draggable class="dragArea" v-model="sortedNodes" item-key="id">
            <div v-for="node in sortedNodes" :key="node.id">
              <p :class="['title', selectedNode?.id === node.id && 'active']" v-html="node?.content" v-touch.passive="()=>select(node)"></p>
            </div>
          </draggable>
        </div>

        <!--      <div v-if="sortedNodes && completeHtml()" v-html="convertDataToHtml(completeHtml().html.blocks)">-->
        <!--      </div>-->
      </div>
    </div>

    <div :class="['rte-container', selectedNode?.id===null ? 'disabled' : '']">
      <RTE ref="editor" class="" :node="selectedNode" :mode="'nice'" @rte:datachange="onRteData"/>
    </div>

    <a id="downloadAnchorElem" style="display:none"></a>

  </div>
</template>

<style lang="scss">
.manuscript {
  margin-top : 65px;

  .flex {
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    margin          : 1em 0;
    padding-left    : 1.25em;

  }

  .nodes {
    position : absolute;
    left     : 0;
    top      : 65px !important;
    width    : 33vw;
    height   : calc(100vh - 65px - 1rem);
    padding  : 0 1rem;

    > div {
      height     : 100%;
      overflow-y : auto;
    }

    .node--item {
      width           : 100%;
      display         : flex;
      align-items     : center;
      justify-content : space-between;

      .switch {
        margin-left  : 5px;
        margin-right : 5px;
      }
    }

    .title {
      cursor     : move;
      padding    : 0.5rem 1rem;
      margin     : 0 0 1px 0;
      flex       : 2;
      background : var(--background-trans-xtra);

      &.main {
        font-weight     : bold;
        display         : flex;
        justify-content : space-between;
        cursor          : default;
        margin-bottom   : 0.75rem;
      }

      &.chapter {
        background : transparent;
        padding    : 7.5px;
      }

      &.child {
        margin-left : 5px;
      }

      &.active {
        background : var(--highlight-color);
      }

    }

    // Main and ChildNodes
    .mNodes > div {
      //border : 1px dotted;
      background : rgba(255, 255, 255, 0.02);
    }

    // draggable: add indicator icon
    .dg {
      position     : relative;
      padding-left : 32px !important;
    }

    .dg:before {
      content  : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -7 24 24' width='24' height='24' preserveAspectRatio='xMinYMin'%3E%3Cpath fill='white' d='M1 0h12a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2zm0 8h12a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2zm0-4h12a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2z'%3E%3C/path%3E%3C/svg%3E");
      opacity  : 0.7;
      position : absolute;
      top      : 5px;
      left     : 0;
    }
  }

  // ------- Adjust RTE
  .rte-container {
    margin-left : 33vw;
    height      : calc(100vh - 65px);
    overflow    : hidden;

    &.disabled {
      .rte #editorjs {
        pointer-events : none;
      }
    }

    .rte .top {
      //position : absolute;
      left    : 33vw;
      top     : 65px;
      right   : 0;
      padding : 5px 10px;
    }

  }

  .message {
    background    : var(--highlight-color);
    margin-bottom : 1em;
  }
}
</style>
