<template>
	<div class="screen" ref="screenRef">
		<slot/>
	</div>
</template>
<script>

import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue"
import panzoom                                              from 'panzoom'

export default {

	props: ['element', 'position', 'panEnabled', 'split'],
	emits: ['update:position', 'panend', 'panning'],

	setup(props, {emit}) {

		const screenRef = ref(null)
		let panzoomInstance = null

		const preferences = {
			minScale: 0.5,
			maxScale: 1
		}

		let viewport

		// HACK. Laaame. Because events / emits of panzoom lib do not work as I expect
		let timer = null

		const editorSize = computed(() => {
			// Todo: get computed dimension from nodes
			return null
		})

		// ------------------------------------------------
		// Go
		// ------------------------------------------------
		onMounted(() => {
			// -------------------------------------------------------
			// Main element to pan, scale

			// -------------------------------------------------------
			// Events
			window.addEventListener('resize', onResize, false)
			// Trigger initial size check
			onResize()

			// -------------------------------------------------------
			// Enable PanZoom
			const elem = document.getElementsByName(props.element)[0] // Todo


			// https://www.npmjs.com/package/panzoom/v/9.4.2
			panzoomInstance = panzoom(elem, {
				canvas:        true,
				step:          0.1,
				center:        true,
				smoothScroll:  true,  // NOTE: panEnd event will trigger on user-release (before end of elastic movement)
				bounds:        false,
				boundsPadding: 1,
				minZoom:       preferences.minScale,
				maxZoom:       preferences.maxScale,
				beforeWheel:   function (e) {
					// allow wheel-zoom only if shiftKEY is down. Otherwise - ignore
					let shouldIgnore = !e.shiftKey;
					return shouldIgnore;
				},
				filterKey:     function (/* e, dx, dy, dz */) {
					// don't let panzoom handle the key event:
					return true;
				},
				// initial
				initialX:    props.position.x || 0,
				initialY:    props.position.y || 0,
				initialZoom: props.position.scale || 1
			})

			// Move to props x/y initial
			setTimeout(() => {
				if (props.position)
					panzoomInstance.moveTo(props.position.x, props.position.y)
				else
					throw Error('No position for stage')
			}, 10)

			// Panning has ended
			panzoomInstance.on('panend', (event) => {
				// Attempt to lock to minimums (0,0) :: x: Math.min(0, event.getTransform().x),
				let t = {
					x:     event.getTransform().x,
					y:     event.getTransform().y,
					scale: event.getTransform().scale
				}
				update(t, false)

				// TODO: unreliable. 2 Options: a) fix or replace the panzoom class | b) update stage position in editor whenever the stage is left/changed/closed
				// HACK ::: ISSUE: Event is fired before the smooth movement has ended.
				if (timer) {
					clearTimeout(timer)
				}
				timer = setTimeout(() => {
					let t = {
						x:     event.getTransform().x,
						y:     event.getTransform().y,
						scale: event.getTransform().scale
					}
					update(t, true)
				}, 1300)
			})

			/**
			 * Any transform event will update (zoom)
			 * Only changed scale will trigger a 'panend'
			 */
			panzoomInstance.on('transform', (event) => {
				update(event.getTransform(), false) // set to true to continously update store with position!
			})

			// Check panZoomEnabled first
			if (!props.panEnabled)
				panzoomInstance.pause()

		})


		/**
		 * Todo: Center stage smoothly (editor 2/2)
		 */
		function centerStage(e) {
			let vpw = (props.split) ? viewport.w / 4 : viewport.w / 2
			let vph = (props.split) ? viewport.h / 4 : viewport.h / 2
			let _coords = !e ? {
					x:     -1 * (editorSize.value.w / 2 - vpw),
					y:     -1 * (editorSize.value.h / 2 - vph),
					scale: panzoomInstance.getTransform().scale
				}
				: e
			panzoomInstance.moveTo(_coords.x, _coords.y)
			update(panzoomInstance.getTransform(), true)
		}

		/**
		 * Todo: Pan stage to a requested position | either percentage or absolute pixels!
		 *
		 * This is usually a node. It will measure and center the node on viewport
		 *
		 * @param pos
		 * @param split BOOL if set, then the ratio is 1/2
		 */
		function panTo(pos = {x: 0, y: 0, scale: 1}, set = false, split = false) {

			pos = {...panzoomInstance.getTransform(), ...pos}

			// console.warn('Pan to ', pos, editorSize.value)
			if (split) console.log('_')
			// let _factor = split ? 4 : 2
			// let _coords = {
			//   x: (pos.x - (viewport.w / _factor)) * -pos.scale,
			//   y: (pos.y - (viewport.h / _factor)) * -pos.scale,
			//   scale: pos.scale
			// }

			// We get percentages:
			let _coords = {
				x:     (pos.x * editorSize.value.w - (viewport.w)) * -pos.scale / 100,
				y:     (pos.y * editorSize.value.h - (viewport.h)) * -pos.scale / 100,
				scale: pos.scale
			}
			panzoomInstance.moveTo(_coords.x, _coords.y)
			update(panzoomInstance.getTransform(), set)
		}

		// ----------------------------------------------------------------------------
		// Pause / Resume
		// ----------------------------------------------------------------------------

		/**
		 * If stage demands a stop to pan events, react:
		 */
		watch(() => props.panEnabled, (v) => {
			if (!panzoomInstance) return
			if (!v) panzoomInstance.pause()
			else panzoomInstance.resume()
		}, {immediate: false})  // immediate doesn't help./ because pz instance wont be ready

		/**
		 * Pause PAN
		 */
		function pause() {
			panzoomInstance.pause()
		}

		/**
		 * Resume PAN
		 */
		function resume() {
			if (props.panEnabled)
				panzoomInstance.resume()
		}

		// ---------------------------------------------------------------------------
		// Utilities
		// ---------------------------------------------------------------------------

		// Update viewport on resize
		const onResize = () => {
			viewport = {
				w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
				h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
			}
		}

		function getViewport() {
			return viewport
		}


		// ----------------------------------------------------------------------------
		// Update
		// ---------------------------------------------------------------------------
		let to = null

		/**
		 * Emit update events
		 * Either <panning> or <panend>
		 * @param coords
		 * @param end
		 */
		function update(coords, end = false) {
			// Let any ancestor know
			emit('panning', {...coords})
			if (end) {
				// If this is the last event, trigger panEnd event
				// We will update the store only then to save some performance
				// Debounce to avoid stutters (lame)
				clearTimeout(to)
				setTimeout(() => {
					emit('panend', {...coords})
				}, 10)
			}
		}


		// ----------------------------------------------------------------------------
		// End
		onBeforeUnmount(() => {
			window.removeEventListener('resize', null)
			if (panzoomInstance)
				panzoomInstance.dispose()
		})


		return {
			screenRef,
			centerStage,
			getViewport,
			pause,
			resume,
			panTo
		}

	},

}
</script>
<style scoped lang="scss">
.screen {
	position : absolute;
	width    : 100%;
	height   : 100%;
	//overflow: hidden;

	}


</style>
