<template>
  <div class="fiIcon">
    <div>
      <slot/>
      <label v-if="label.length">{{ label }}</label>
    </div>
  </div>
</template>
<script>
export default {
  name: "fiIcon",
  props: ["label"]
}
</script>
<style lang="scss" scoped>


div.fiIcon {
  cursor  : pointer;
  display : inline-block;

  div {
    display        : flex;
    flex-direction : column;
    align-items    : center;
    padding        : 0.5em;
  }

  // Button type: (NYI)
  &.asButton {
    border        : 1px solid;
    border-radius : 4px;
    margin-right  : 2px;
    background    : rgba(255, 255, 255, 0.05);
  }

  //

  &:hover :slotted(svg) {
    transform : scale(1.25);
    fill      : var(--highlight-color);
  }

}

label {
  font-weight    : 100;
  font-size      : 9px;
  text-transform : uppercase;
}

// Svg icons inside slot:
:slotted(svg) {
  font-size  : 1.5em;
  cursor     : pointer;
  fill       : var(--text-color);
  float      : right;
  padding    : 5px;
  width      : 1.5em;
  height     : 1.5em;
  transition : all 0.21s ease-in-out;

}

</style>
