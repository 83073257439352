<template>
  <div class="fontPicker">
    <OptionsSubPanel :open="open">
      <ul>
        <li v-for="fnt in fonts" :key="fnt.type" :style="`font-family:${fnt.type}`" @click="pickFont(fnt.type)" :class="fnt.type === font || (fnt.type === 'sans' && font === '') ? 'selected' : ''">
          A/a
        </li>
      </ul>
    </OptionsSubPanel>
    <a class="font" @click="toggle">
      <div class="fontInner" :style="`font-family:${font}`">F</div>
    </a>
  </div>
</template>
<script>
/* eslint-disable */

import { computed, ref } from "vue"
import OptionsSubPanel   from "@/components/OptionsSubPanel";

export default {
  name: 'FontPicker',
  components: {OptionsSubPanel},
  emits: ['update:modelValue', 'fontPick', 'open'],
  props: ['font'],
  setup(props, {emit}) {

    let open = ref(false)
    let picker = ref(null)

    // Fonts
    const fonts = ref([
      {type: 'one', name: 'Scope One'},
      {type: 'sans', name: 'Metropolis Light'},
      // {type: 'serif', name: 'Baskervville'},
      {type: 'typewriter', name: 'Lekton'},
      {type: 'hand', name: 'Caveat'},
    ])

    // Color data props [UNUSED!]
    const appliedFont = computed({
      get: () => {
        return props.font || 'sans'
      },
      set: (val) => emit('update:modelValue', val),
    });


    function toggle() {
      open.value = !open.value
      if (open.value) emit('open')
    }

    function pickFont(font) {
      emit('fontPick', font)
      open.value = false
    }


    return {
      fonts,
      picker,
      open,
      toggle,
      pickFont,
      close: () => {open.value = false}
    }
  }


}
</script>
<style lang="scss" scoped>
@import "src/styles/variables";

$invertedBg : #eee;

.fontPicker {
  position : relative;
  z-index  : 100;

  .track {
    //left          : -100px;
    @media (max-width : 940px) {
      left  : auto;
      right : 100px;
    }
  }

  .font {
    display       : block;
    width         : 32px;
    height        : 32px;
    line-height   : 34px;
    border-radius : 50%;
    background    : var(--primary-color);
    border        : 1px solid var(--text-color);
    font-size     : 21px;
    font-weight   : 100;
    text-align    : center;
    cursor        : pointer;

    .fontInner {
      color : inherit;
    }
  }

  ul {
    margin  : 0;
    padding : 0;

    li {
      list-style    : none;
      display       : inline-block;
      width         : 2.15em;
      height        : 2.15em;
      line-height   : 2.15em;
      text-align    : center;
      font-size     : 1.76em;
      border        : 1px solid var(--text-color);
      border-radius : 50%;
      margin-right  : 10px;
      cursor        : pointer;

      &:hover, &.selected {
        background : var(--primary-color);
        color      : var(--text-color);
      }

    }
  }


  .picker {
    width  : 100%;
    height : 100%;
  }

}

// Dark style
.stage.dark {
  //.fontPicker .track {
  //  //background : var(--text-color);
  //
  //  li {
  //    border-color : var(--text-color);
  //  }
  //}

  .font {
    border-color : var(--primary-color);
    background   : var(--text-color);
    color        : var(--primary-color);
  }

}


</style>
