<template>
  <div :class="`sketchpad ${locked && 'locked'}`">
    <VueSignaturePad :id="skId" ref="sketch" width="100%" height="100%" :options="sketchOptions"/>
    <nav>
      <ul>
        <li>
          <icon-pencil v-show="useEraser" @click="colorSketch()"/>
          <icon-rubber v-show="!useEraser" @click="eraseSketch(true)"/>
        </li>
        <li>
          <icon-undo @click="undoSketch"/>
        </li>
        <!--        <li>-->
        <!--          <icon-share-alt @click="exportAs()"/>-->
        <!--        </li>-->
      </ul>
    </nav>
    <div v-show="scale < 1" class="message">
      {{ $t('scale_to_draw') }}
    </div>
  </div>
</template>
<script>
/* eslint-disable */
/*
Others
- https://yarnpkg.com/package/atrament

 */

import { nextTick, onMounted, ref, watch } from "vue";
import { uuid }                            from "vue-uuid";


export default {
  name: 'Sketchpad',
  props: ['data', 'locked', 'bgStyle', 'scale'],
  emits: ['dataUpdate'],
  setup(props) {
    const skId = ref(uuid.v1())
    let canvas, ctx
    let ready = ref(false)
    let width = ref(800)
    let height = ref(800)

    const sketch = ref(null)

    // Nav
    const useEraser = ref(false)

    // Options
    let sketchOptions = ref({
      dotSize: 1,
      throttle: 8,
      minWidth: 0.25,
      maxWidth: 1.5,
      penColor: 'black'
    })


    // Close or open signature:
    watch(() => [props.locked, props.scale], () => {
      // Now lock the pad if props want it OR scale !== 1 (Todo: implement to draw on scaled canvas)
      if ((props.locked || props.scale !== 1) && sketch.value)
        sketch.value.lockSignaturePad()
      else if (!props.locked && sketch.value && props.scale === 1)
        sketch.value.openSignaturePad()
    }, {
      immediate: true
    })

    // Adapt colour to stage background style
    watch(() => props.bgStyle, (nv, ov) => {
      // Change pen color to be in contrast
      applyNewPenColor(nv)
    }, {
      immediate: false
    })

    // Watch data (they will need some time)
    watch(() => props.data, (nv, ov) => {
      if (ov !== nv && ready.value) {
        setSize()
        setData(props.data)
        applyNewPenColor(props.bgStyle)
      }
    }, {
      immediate: false
    })

    onMounted(() => {
      canvas = document.getElementById(skId.value).getElementsByTagName('canvas')[0]
      ctx = canvas.getContext('2d')
      nextTick(()=>{
        applyNewPenColor(props.bgStyle)
        // Ready to show
        ready.value = true
      })

    });

    /**
     * Set data
     * @param data JSON
     */
    const setData = (data) => {
      sketch.value.clearSignature()
      sketch.value.fromData(data)
    }

    /**
     * Get current sketch
     * @return {*} JSON
     */
    const getData = () => {
      return sketch.value.isEmpty() ? [] : sketch.value.toData()
    }

    // Apply a new colour for the lines
    const applyNewPenColor = (penColor) => {
      // Set
      sketchOptions.value.penColor = penColor
      sketch.value.signaturePad.penColor = penColor

      // Change, if props are not empty || null
      if (!props.data || props.data && !props.data.length) return
      let newData = []
      props.data.map(l => {
        newData.push({...l, ...{color: penColor}})
      })
      setData(newData)
      // emit('dataUpdate') // we dont use a model, so let parent know
    }

    /**
     * Erase a sketch
     * @param full BOOL is true to delete the whole screen
     * we do not use full=false as this messes with undo - all or nothing!
     */
    const eraseSketch = (full = false) => {
      if (!full) {
        // @NOT USED!
        useEraser.value = true
        ctx.globalCompositeOperation = 'destination-out';
      } else {
        // delete all
        sketch.value.clearSignature()
        // emit('dataUpdate', null)
      }

    }

    /**
     * Set paint mode  andcolor
     * @param color
     */
    const colorSketch = () => {
      useEraser.value = false
      ctx.globalCompositeOperation = 'source-over'; // default value
    }

    const undoSketch = () => {
      sketch.value.undoSignature()
    }

    const exportAs = (type = 'svg') => {
      if (sketch.value.isEmpty()) {
        return alert("Well, sketch something first...");
      }

      const {data} = sketch.value.saveSignature()
      const download = document.createElement('a');
      download.href = data;
      download.download = 'Fizzle sketch';
      download.click();
    }

    const setSize = () => {
      const ratio = Math.max(window.devicePixelRatio || 1, 1)
      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext("2d").scale(ratio, ratio)

      // sketch.value.clearSignature(); // otherwise isEmpty() might return incorrect value

      // ----------------------------------------------------
      // Scale all points as well? We need previous size vs. new size to get a SCALE
      // let newData = []
      // props.data.forEach(l => {
      // l.points.forEach(p=>{
      //  // apply new scale
      //  p.x = p.x * scale
      //  p.y = py. .....
      // })
      // newData.push({ ...l, ...{ points:  } })
      // })
      // sketch.value.clearSignature()
      // sketch.value.signaturePad.penColor = penColor
      // setData(newData)
      // emit('dataUpdate') // we dont use a model, so let parent know
      // -------------------------------------------------

      // Repaint
      // emit('dataUpdate')
      sketch.value.fromData(getData()) // repaint with current data
    }

    const getSize = () => {
      return canvas.getBoundingClientRect()
    }

    return {
      skId,
      sketch,
      ready,
      width,
      height,
      useEraser,
      sketchOptions,
      undoSketch,
      colorSketch,
      eraseSketch,
      // Public
      getData,
      setData,
      exportAs,
      setSize,
      getSize
    }
  }

}
</script>
<style scoped lang="scss">
.sketchpad {
  width  : 100%;
  height : 100%;
  //cursor : crosshair;
  border : 1px dashed rgba(100, 100, 100, 0.25);

  &.locked {
    pointer-events : none;
  }

  nav, .message {
    position       : absolute;
    top            : 10px;
    right          : 10px;
    z-index        : 2000;

    ul {
      margin        : 0;
      padding       : 10px;
      background    : rgba(0, 0, 0, 0.33);
      border-radius : 8px;

      li {
        list-style : none;

        &:not(:last-child) {
          margin-bottom : 1rem;
        }
      }

      small {
        clear : both;
      }
    }

    .dark & {
      color : white;

      svg {
        fill : white;
      }
    }

    opacity        : 0;
    pointer-events : none;

    .active & {
      opacity        : 1;
      pointer-events : auto;
    }

  }

  .message {
    left       : 50%;
    top        : 50%;
    right      : auto;
    transform  : translate3d(-50%, -50%, 0);
    width      : 120px;
    background : rgba(0, 0, 0, 0.6);
    z-index    : 2;
  }

}
</style>
