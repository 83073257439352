<template>
	<div id="projectManager" class="flex-container full has-logo has-nav">

		<draggable v-if="projectCollection" class="dragArea" v-model="projectCollection">
			<div v-for="element in projectCollection" :key="element.id">
				<div :class="[`project`,  !element._id && 'new', projectBusy && currentProjectId === element._id && 'busy']" :data-id="element._id">

					<icon-plus v-show="!element._id && !editTitle" class="icon-control add"/>

					<div :class="['flex-container', !element._id ? 'new': 'handle']"
					     v-touch.stop="()=>openProject(element)">

						<small v-if="element._conflicts?.length">
							<icon-refresh/>
						</small>
						<!--            <small>{{element?.device}}</small>-->
						<!--            <small>{{ element.author }}</small>-->
						<!--            <small>{{ element?._rev?.split('-')[0] }}</small>-->

						<textarea :ref="setProjectRef"
						          v-model="element.title"
						          v-show="(!element._id && editTitle) || element._id"
						          v-touch:longtap.stop="($event)=>checkTitle($event, element)"
						          v-touch.prevent.stop="()=>{return false}"
						          @keyup="checkTitle($event, element)"
						          @blur="checkTitle($event, element)"/>

						<!-- Add new -->
						<div class="fb1">
							<div class="la-square-jelly-box"
							     style="left:calc(50% - 1rem);opacity: 0.6"
							     v-show="!editTitle && !element._id">
								<div></div>
								<div></div>
							</div>
						</div>

						<!-- Free: create another project -->
						<div v-if="!element._id && !editTitle && appLevel === 0" style="text-align: center;">
							<!-- Copy for free user -->
							<p v-if="projects.length === 0">{{ $t("pm.create_project") }}</p>
							<!-- Get the app (free mode)  -->
							<div v-if="projects.length > 0" style="text-align: center;">
								<p>
									{{ $t("pm.get_more") }}
									<br/>
								</p>
								<div class="align-center">
									<span class="button block small">{{ $t("nav.get_app") }}</span>
								</div>
							</div>
						</div>
						<!-- // free -->

						<!-- Add another, if appLevel > 0 || or 0 projects && appLevel === 0  -->
						<a href="javascript:void(0)"
						   v-if="editTitle && !element._id && ((appLevel === 0 && projects.length < 1) || appLevel >= 1)"
						   class="button block"
						   v-touch.self.stop="()=>addProject(element)">
							{{ $t("pm.create").toUpperCase() }}
						</a>

					</div>

					<nav v-if="element._id">
						<icon-trash v-touch.self="()=>deleteProject(element._id)" class="icon-control delete"/>
						<div>
							<icon-book-f v-touch.self.stop="()=>openManuscriptView(element._id)" class="icon-control"/>
							<icon-download v-if="!system.app && system.isDev" v-touch.self.stop="()=>download( element)" class="icon-control download"/>
						</div>
					</nav>

				</div>
			</div>
		</draggable>

		<div>
			<a id="downloadAnchorElem" style="display:none"></a>

			<div class="note" v-if="appLevel === 0">
				<p v-html="$t('free_note')"></p>
			</div>

			<div id="devnotes" v-if="devnote && system.isDev">
				<pre>{{ devnote }}</pre>
			</div>
		</div>

		<!--    <draggable id="archive" v-model="archivedProjects" @drop="onDropOnArchive($event)" item-key="_id">-->
		<!--      <template #item="{ element, index }">-->
		<!--        <div class="list-group-item">{{ element.name }} {{ index }}</div>-->
		<!--      </template>-->
		<!--    </draggable>-->

		<div id="loadingOvl" v-show="wait">
			<div class="la-square-jelly-box"
			     style="opacity: 0.6; margin-bottom:1rem;">
				<div></div>
				<div></div>
			</div>
			<div>{{ $t('pm.wait') }}</div>
		</div>


		<Modal v-if="showConflictsModal" class="is-one">
			<strong>Resolve conflicts</strong>
			<p>Your document is not in sync. Choose the one you want to keep</p>
			<div style="display: flex;">
				<div>
					Rev: {{ conflictedProject?._rev.split('-')[0] }} <br/>
					Device: {{ conflictedProject.device }} <br/>
					Time: {{ new Date(conflictedProject.time * 1000).toLocaleDateString() }} {{ new Date(conflictedProject.time * 1000).toLocaleTimeString() }}
					<br/><br/>
					<fi-button class="button small" v-touch:tap="()=>resolveConflicts(1)" style="margin-right:2rem;">Keep mine</fi-button>
				</div>
				<hr/>
				<div>
					Rev: {{ conflictedProjectRemote?._rev.split('-')[0] }} <br/>
					Device: {{ conflictedProjectRemote?.device }} <br/>
					Time: {{ new Date(conflictedProjectRemote?.time * 1000).toLocaleDateString() }} {{ new Date(conflictedProjectRemote?.time * 1000).toLocaleTimeString() }}
					<br/><br/>
					<fi-button class="button small" v-touch:tap="()=>resolveConflicts(0)">Keep the other from&nbsp;<strong>{{ conflictedProjectRemote?.device }}</strong></fi-button>
				</div>
			</div>
			<!--      <div class="controls" style="display:flex;    justify-content: space-between;">-->
			<!--        <fi-button class="button small" v-touch:tap="()=>resolveConflicts(1)" style="margin-right:2rem;">Keep mine</fi-button>-->
			<!--        <fi-button class="button small" v-touch:tap="()=>resolveConflicts(0)">Keep the other from&nbsp;<strong>{{ conflictedProject.device }}</strong></fi-button>-->
			<!--      </div>-->
		</Modal>

	</div>
</template>

<script>
/* eslint-disable */
import { computed, nextTick, onBeforeUnmount, onBeforeUpdate, onMounted, reactive, ref } from "vue"
import { useRouter }                                                                     from 'vue-router'
import { useI18n }                                                                       from "vue-i18n";
import { storeToRefs }                                                                   from "pinia";
import { notify }                                                                        from "@kyvg/vue3-notification";
import { appStateStore }                                                                 from "@/store/appState";
import { useUserStore }                                                                  from "@/store/user";
import { useProjectCollectionStore }                                                     from "@/store/projectCollection";
import Modal                                                                             from "@/components/Modal";
import FiButton                                                                          from "@/components/fiButton";
import { projectModel }                                                                  from "@/models";
import { apiGetProject, apiRemoveConflicts }                                             from "@/store/api";
import { VueDraggableNext as draggable }                                                 from "vue-draggable-next";

export default {
	components: {Modal, FiButton, draggable},
	props:      ['user', 'offline'],
	setup() {
		const router = useRouter()
		const i18n = useI18n()

		const userStore = useUserStore()
		const {appLevel} = storeToRefs(userStore)

		const appState = appStateStore()
		const {system, busy, wait, syncing} = storeToRefs(appState)

		const projectCollStore = useProjectCollectionStore()
		const {projects, archivedProjects} = storeToRefs(projectCollStore)

		// const selectedProject = ref(null) /// keep track of currently selected.

		// References to text areas
		let projectRefs = reactive([])

		// Title for new projects
		const editTitle = ref(false)

		// Keep the dragged element when started. NIY. (archive fn)
		const draggedEl = ref(null)

		// Show developer info. If dev.
		let devnote = {...system.value}
		const debug = ref(system.value.isDev)

		/**
		 * Get projects
		 * @type {WritableComputedRef<*[]|{connectors: [], author: null, title: string, version: number, archived: number, _attachments: {}, hasAttachment: boolean, currentParentNodes: [{id: number},{id: number}], nodes: [], stages: [{background: null, style: string, id: number, position: {x: number, y: number, scale: number}, title: string, order: number}], _id: null, categories: [], time: null, currentParentNodePointer: number, order: number}[]>}
		 */
		const projectCollection = computed({
			get: () => {
				// Add an empty project and auto=bump the numer in default title
				const _empty = {...projectModel, ...{title: `${ i18n.t('new_fizzle') } ${ (+projects.value.length + 1) }`}}
				return projects.value.length ? [...projects.value, _empty] : [_empty]
			},
			set: (val) => {
				val.pop() // remove our empty project
				// Note: We use onSort() to sort projects ~ seems better, because the computed value will snap back to original state before async is complete
				projectCollStore.updateProjectList(val)
			}
		})


		// Open or create a project
		const currentProjectId = ref(null)
		const projectBusy = ref(false)
		const openProject = async (project) => {
			currentProjectId.value = project._id    // pointer to currently selected project
			projectBusy.value = true
			// Conflict handling
			// If there are some, show a note to the user
			// who can decide which version to keep
			if (project._conflicts && project._conflicts.length > 0) {
				await handleConflicts(project)
				projectBusy.value = false
				return
			}

			if (!project._id) createProject()
			else router.push(`/edit/${ project._id }`)
		}

		// Create a project :: Enable title field and wait:
		const createProject = index => {
			// Free version allows one project only
			if (appLevel.value === 0 && projects.value.length >= 1) {
				router.push('/options')
				return
			}
			projectBusy.value = false
			// Ask for title
			editTitle.value = true
			// newTitle.value = `My fizzle ${projects.value.length + 1}`
			nextTick(() => {
				const titleRef = projectRefs[projectRefs.length - 1]
				if (titleRef)
					titleRef.focus()
			})

		}

		// Listen for ENTER || BLUR and end editing of existing project (title)
		function checkTitle(e, project) {
			// Auto-Size
			if (!e) return
			e.target.style.height = ""; /* Reset the height*/
			e.target.style.height = Math.min(e.target.scrollHeight, 120) + "px";
			// Save on blur || enter
			if (e.code === 'Enter' || e.keyCode === 'Enter') e.target.blur()
			// Blurred : update
			if (e.type === 'blur' && project._id) {
				project = {...project, ...{title: e.target.value.replace(/^\s+|\s+$/g, '')}}
				projectCollStore.updateProject(project)
				editTitle.value = false
				e.target.blur()
			}
		}

		// Add the project to the store and open it instantly
		async function addProject(newProject) {
			const project = await projectCollStore.addProject({title: newProject.title, order: projects.value.length + 1})
			if (project) {
				editTitle.value = false
				router.push(`/edit/${ project._id }`)
			} else
				notify(i18n.t('could_not_create_project'))
		}

		// Remove one
		async function deleteProject(id) {
			if (confirm(i18n.t('pm.remove_project_confirm'))) {
				projectCollStore.deleteProject(id)
			}
		}

		// Download json project (unused)
		function download(project) {
			const dataStr = "data:text/json;charset=utf-8," + btoa(unescape(encodeURIComponent(JSON.stringify(project))));
			const dlAnchorElem = document.getElementById('downloadAnchorElem');
			dlAnchorElem.setAttribute("href", dataStr);
			dlAnchorElem.setAttribute("download", `${ project.title }.fiz`);
			dlAnchorElem.click();
		}

		// NYI: Add to archive.
		function onDropOnArchive() {
			// const id = draggedEl.value.item.getAttribute('data-id')
			// Todo: update the .archived tag
			// console.log('DROPPED FOR ARCHIVE', draggedEl.value.item.getAttribute('data-id'))
		}


		// ---- Conflict resolution
		const conflictedProject = ref(null)
		const conflictedProjectRemote = ref(null)
		const showConflictsModal = ref(false)  // awkward - transports id of current project
		// Todo: conflict res should be in APP to be global. In case someone is within an open project...
		const handleConflicts = async (project) => {
			// Check what revs to match
			// The current local one vs. the remote one
			conflictedProjectRemote.value = await apiGetProject(project._id, project._conflicts[0])
			// console.log(project.time, project._rev, 'vs', conflictedProjectRemote.value._rev)

			if (conflictedProjectRemote.value &&
				project._rev.split('-')[0] <= conflictedProjectRemote.value._rev.split('-')[0]) {
				// Show the conflict modal to user if we have 2 revs of the same.
				// Show Time and Device
				showConflictsModal.value = true
				conflictedProject.value = project

			} else {
				// autoClean
				await apiRemoveConflicts(project._id)
				router.push(`/edit/${ project._id }`)
			}

		}

		/**
		 * Resolve conflicts on user's choice
		 * @param doIt
		 * @returns {Promise<void>}
		 */
		const resolveConflicts = async (doIt) => {
			// 1 = delete all revisions
			// 0 = ignore and open
			if (doIt === 1) {
				await apiRemoveConflicts(conflictedProject.value._id)
				router.push(`/edit/${ conflictedProject.value._id }`)
			} else {
				router.push(`/edit/${ conflictedProject.value._id }`)
			}
			showConflictsModal.value = false
		}


		onMounted(() => {
			projectCollStore.getProjects()
		})

		onBeforeUpdate(() => {
			projectRefs = []
		})

		onBeforeUnmount(() => {
			// Events

		})


		return {
			appLevel,
			archivedProjects,
			system,
			projectCollection,
			currentProjectId,
			projectBusy,
			syncing,
			wait,
			devnote,
			debug,
			busy,
			projects,
			editTitle,
			draggedEl,
			onDropOnArchive,
			onDragStart:        (el) => {
				draggedEl.value = el
			},
			setProjectRef:      (el) => {
				projectRefs.push(el)
			},
			openManuscriptView: (id) => {
				router.push(`/manuscript/${ id }`)
			},
			openProject,
			addProject,
			createProject,
			deleteProject,
			checkTitle,
			download,
			//
			showConflictsModal,
			conflictedProject,
			conflictedProjectRemote,
			handleConflicts,
			resolveConflicts
		}
	}
}
</script>

<style lang="scss">
@import "../styles/variables";
@import "../styles/loader";

// Project manager window
#projectManager {

	overflow                   : auto;
	overflow-x                 : hidden;
	-webkit-overflow-scrolling : touch; /* lets it scroll lazy */

	.inner, .dragArea {
		color          : var(--text-color);
		display        : flex;
		flex-direction : row;
		flex-wrap      : wrap;
		}

	.project {
		cursor         : pointer;
		position       : relative;
		width          : 12vw;
		height         : 16vw;
		min-width      : 160px;
		min-height     : 200px;
		max-width      : 160px;
		max-height     : 212px;
		margin         : 1.5vh 1vw;
		//background     : var(--background-trans-light);
		color          : var(--primary-color);
		//padding        : 10px;
		//border         : 1px solid var(--text-color);
		border-radius  : 6px;
		display        : flex;
		flex-direction : column;
		align-items    : stretch;
		overflow       : hidden;

		box-shadow     : rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

		.flex-container {
			max-width               : 100%;
			background              : var(--background-trans-light);
			padding                 : 10px;
			box-shadow              : rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
			z-index                 : 1;
			border-top-left-radius  : 4px;
			border-top-right-radius : 4px;
			}

		&.sortable-chosen {
			background : white;
			}

		&.busy {
			pointer-events : none;
			opacity        : 0.25;
			}

		&.new {
			background : var(--background-trans-xtra);
			border     : 1px solid;
			color      : var(--text-color);
			padding    : 10px;

			.flex-container {
				background : transparent;
				box-shadow : none;
				}

			svg path {
				fill : var(--text-color)
				}
			}

		.fb1 {
			display         : flex;
			justify-content : center;
			flex            : 1;
			flex-direction  : column;
			}

		textarea {
			font-family : $font;
			font-weight : bold;
			background  : transparent;
			//border      : 1px solid var(--text-color);
			border      : none;
			color       : inherit;
			font-size   : 16px;
			padding     : 4px;
			resize      : none;
			max-width   : calc(100%);
			}

		// Button update
		.button {
			//background : transparent;
			margin : 0;
			}

		// Nav
		nav {
			display         : flex;
			justify-content : space-between;
			background      : var(--background-trans-light);
			padding         : 10px;
			z-index         : 0;

			.icon {
				cursor        : pointer;
				border        : 1.5px solid var(--background-color);
				color         : var(--background-color);
				fill          : var(--background-color);
				border-radius : 50%;
				padding       : 3px;
				margin-left   : 5px;
				width         : 24px;
				height        : 24px;

				&:hover {
					background : var(--primary-color);
					color      : var(--text-color);
					fill       : var(--text-color);

					&.delete {
						background : var(--highlight-color);
						}
					}

				}
			}

		}

	// Single projects

	#archive {
		position      : fixed;
		bottom        : 0;
		right         : 0;
		width         : 15vw;
		height        : 15vw;
		border-radius : 50%;
		background    : rgba(255, 255, 255, 0.5);
		}

	}

.note {
	position   : fixed;
	bottom     : 20px;
	width      : 21vw;
	max-width  : 310px;
	color      : var(--text-color);
	background : rgba(255, 255, 255, 0.1);
	padding    : 1em;
	z-index    : 0;

	}

// Dev only
#devnotes {
	position   : fixed;
	right      : 0;
	bottom     : 0;
	background : rgba(255, 255, 255, 0.05);
	padding    : 15px;
	font-size  : 10px;
	}

#loadingOvl {
	position        : fixed;
	z-index         : 200;
	top             : 65px;
	left            : 0;
	right           : 0;
	bottom          : 0;
	background      : rgba(0, 0, 0, 0.1);
	backdrop-filter : blur(4px);
	display         : flex;
	flex-direction  : column;
	justify-content : center;
	align-items     : center;
	text-align      : center;

	}

</style>
