<script>
/* eslint-disable */
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import { VueDraggableNext }                                              from 'vue-draggable-next'
import { clamp }                                        from "@/utils";
import { stageModel }                                   from "@/models";


export default {

  components: {draggable: VueDraggableNext},

  props: {
    split: {
      type: Boolean,
      default: false
    },
    activeStage: {type: Number, default: 0},
    nodeSet: {type: Array, default: () => []},
    background: {type: String, default: ""},
    stagePosition: {
      type: Object,
      default: () => {
        return stageModel.position
      }
    }
  },

  setup(props, {emit}) {

    const miniStage = ref(null) // ref to view
    const miniEditor = ref(null) // the moving rect
    // Editor DOM object
    // let editor = null

    // The ministage position and scale to update
    // const position = ref({x: 0, y: 0, width: 0, height: 0})
    let isDragging = ref(false)

    const active = ref(false)
    const toggleActive = () => {
      active.value = !active.value
      nextTick(() => {
        emit('miniview:resize')
      })
    }


    // ----------------- Update fn. Called by observers

    // Translated stage position, set by observer
    const pos = computed(() => props.stagePosition)

    // Computed position
    const position = computed({
      get: () => {
        // Calculate the position and size of the red miniStage
        // return getTranslatedPosition(pos)
        // Todo: to make this work, we need to decouple drag & stage pos  - to avoid infinite loop
        return !isDragging.value ? getTranslatedPosition(pos) : getTranslatedPosition({x:0,y:0,h:0,w:0,scale:1})
      },
      set: (v) => {
        // set -> if not dragging, allow this to be put to store.
        // console.warn('SETTING ', v, isDragging.value)
        emit('miniview:drag', {pos: v, set: !isDragging.value})
      }
    })

    // Mini view position / size
    const miniStageSize = ref({})

    // ---- translate from stage to ministage
    const getTranslatedPosition = (pos) => {
      if (!miniStage.value || !pos.value) return {}

      // Editor size is fixed
      const editorSize = {
        width: 20000,
        height: 10000
      }
      // The viewport size to editor size ratio is muy importante. == STAGE
      // Todo: if split, it's w/2
      viewport = {
        ...viewport, ...{
          vp2eW: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) / editorSize.width,  // viewport / stage to editor width ratio
          vp2eH: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) / editorSize.height
        }
      }
      // Calculate the position and size of the red miniStage
      return {
        x: miniStageSize.value.width / 100 * (pos.value.x * -1) / editorSize.width * 100 / pos.value.scale
        , y: miniStageSize.value.height / 100 * (pos.value.y * -1) / editorSize.height * 100 / pos.value.scale
        , width: miniStageSize.value.width / 100 * viewport.vp2eW * 100 / pos.value.scale
        , height: miniStageSize.value.height / 100 * viewport.vp2eH * 100 / pos.value.scale,
        scale: pos.value.scale
      }
    }

    // ---- translate from ministage to stage
    const getTranslatedPositionFromMiniView = (pos) => {
      // always shift to top/left of miniStage
      // console.log(pos, (pos.x - miniStageSize.value.x - startOffset.x) / miniStageSize.value.width * 100)
      return {
        x: clamp((pos.x - miniStageSize.value.x - startOffset.x) / miniStageSize.value.width * 100, 0, 100),
        y: clamp((pos.y - miniStageSize.value.y - startOffset.y) / miniStageSize.value.height * 100, 0, 100),
        width: position.value.width,
        height: position.value.height,
        scale: position.value.scale
      }
    }

    // ---- Node position translation [show mini nodes]
    const nodeSetTranslated = computed(() => {
      if (!miniStage.value) return props.nodeSet
      const fw = miniStageSize.value.width / 20000,
          fh = miniStageSize.value.height / 10000
      // console.info('--------- node calc ---------', active.value)
      let ns = props.nodeSet.map(n => {
        // console.log('NODE translation ', n.position.y, n.position.y * fh)
        return {
          ...n, ...{
            left: Math.floor(n.position.x * fw),
            top: Math.floor(n.position.y * fh),
            width: Math.round(n.position.w * fw),
            height: Math.round(n.position.h * fh)
          }
        }
      })
      // console.log(ns)
      return ns
    })

    // -------------- Drag and position

    // Store initial position of event to target (dragged miniStage)
    const startOffset = reactive({x: 0, y: 0})
    const onDragStart = (e) => {
      isDragging.value = true
      startOffset.x = e.offsetX
      startOffset.y = e.offsetY
      // Hide the original
      setTimeout(() => {
        e.target.classList.add('hide');
      }, 0)
    }

    // Get position in relation to miniStage and move main stage
    const onDrag = (e) => {
      if (e.screenX === 0 && e.screenY === 0) // is it a bug? When released, the coords are somewhat screwed
      {
        isDragging.value = false
        position.value = getTranslatedPositionFromMiniView({x: e.pageX, y: e.pageY})
        return
      }
      // Translate to stage
      position.value = getTranslatedPositionFromMiniView({x: e.pageX, y: e.pageY})
      // console.log(position.value)
    }

    // Drag ended, nothing.
    const onDragEnd = (e) => {
      isDragging.value = false
      // position.value = getTranslatedPositionFromMiniView({x: e.pageX, y: e.pageY})
      // console.log('DE ', position.value.x)
      // Show the original
      setTimeout(() => {
        e.target.classList.remove('hide');
      }, 0)
    }

    // ----------- Viewport and resize watcher :: Todo: if we have a computed fn, we do not need this one.
    let viewport = {}
    const resizeObserver = new ResizeObserver(() => {
      viewport = {
        width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
        // Editor size vs. stage size ratio
        fractionW: 0,
        fractionH: 0
      }
    })


    // ----------- Observe changes to miniStage / miniView
    const mvResizeObserver = new ResizeObserver(function() {
      miniStageSize.value = miniStage.value.getBoundingClientRect()
    });


    // ---------- Mounted
    onMounted(() => {
      // used to use mutationobserver, now direct props
      resizeObserver.observe(document.documentElement)
      mvResizeObserver.observe(document.getElementById('miniView'));

    })

    onBeforeUnmount(()=>{
      resizeObserver.disconnect()
      mvResizeObserver.disconnect()
    })

    return {
      miniEditor,
      miniStage,
      position,
      nodeSetTranslated,
      active,
      toggleActive,
      onDragStart,
      onDrag,
      onDragEnd
    }
  }
}

</script>

<template>
  <div id="miniViewWrapper" :class="[active && 'active', split && 'split' ]" @v-touch:tap="toggleActive" @click="toggleActive">
    <div id="miniView"
         ref="miniStage"
         :style="background"
    >
      <div v-if="nodeSetTranslated">
        <div v-for="node in nodeSetTranslated" :key="`mininode-${node.id}-${node.time}`" class="tinyNode"
             :style="`left:${node.left}px;
               top:${node.top}px;
               width:${node.width}px;
               height:${node.height}px;`">
        </div>
      </div>
      <draggable>
<!--        {{ miniStageSize }}-->
        <!-- Scalable and draggable model of user's view -->
        <div class="miniEditor"
             ref="miniEditor"
             @dragstart="onDragStart"
             @drag="onDrag"
             @dragend="onDragEnd"
             :style="`left:${position.x}px;top:${position.y}px;width:${position.width}px;height:${position.height}px;`">
        </div>
      </draggable>
    </div>
  </div>
</template>

<style lang="scss" scoped>

#miniViewWrapper {
  // Ratio needs to be the same as editor view 2:1
  width      : 80px;
  height     : 40px;
  background : #333; // !

  // If active, scale up:
  &.active {
    width         : 600px;
    height        : 300px;
    position      : absolute;
    right         : 0;
    bottom        : 0;
    z-index       : 500;
    border        : 10px solid #ccc;
    border-radius : 4px;

    // Split mode = 1/2
    &.split {
      width : 300px;
    }
  }

}

// miniStage box
#miniView {
  width      : 100%;
  height     : 100%;
  cursor     : crosshair;
  position   : relative;
  background : white; // todo: match default.
  //
  margin     : 0 0 5px 0;
  overflow   : hidden;

  // The mini stage [bordered]
  .miniEditor {
    position   : absolute;
    border     : 2px solid red;
    background : rgba(255, 255, 255, 0.2);
    z-index    : 200;

    &.hide {
      opacity : 0;
    }
  }

  // Tiny nodes
  .tinyNode {
    background : #888;
    position   : absolute;
    min-width  : 4px;
    min-height : 2px;
  }

}

</style>
