export function isEmptyObject(obj) {
	return obj // 👈 null and undefined check
		&& Object.keys(obj).length === 0
		&& Object.getPrototypeOf(obj) === Object.prototype
}



export function calculateLineHeadPosition(ev, element) {
	const progressWidth = element.getBoundingClientRect().width;
	const leftPosition = ev.target.getBoundingClientRect().left;
	let pos = (ev.clientX - leftPosition) / progressWidth;

	try {
		if (!ev.target.className.match(/^ar-line-control/)) {
			return;
		}
	} catch (err) {
		return;
	}

	pos = pos < 0 ? 0 : pos;
	pos = pos > 1 ? 1 : pos;

	return pos;
}

export function convertTimeMMSS(seconds = 0) {
	// return new Date(seconds * 1000).toISOString().substr(11, 8)
	return new Date(seconds * 1000).toISOString().substr(14, 5);
}

// // Simple: ss:mm  (mm == millisecs)
// export function convertTimeSSMM(seconds = 0) {
// 	return `${('00' + Math.floor(seconds)).slice(-2)}:${('00' + seconds).slice(-2)}`
// }
//



// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
	let timeout;
	return function () {
		let context = this, args = arguments
		let later = function () {
			timeout = null
			if (!immediate) func.apply(context, args)
		};
		let callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	};
}



