<template>
  <div ref="panel" :class="['options-panel', align , active && 'active']">

    <!--    <div class="panel first vertical">-->
    <!--      <img :src="require('@/assets/logo.svg')" style="width: 30px;margin: 20px 10px 10px;}"/>-->
    <!--    </div>-->


    <!-- Font style -->
    <div :class="['panel first fornode', node?.type !== 'default' ? 'inactive' : 'active' ]">
      <h5>{{ $t('options.font').toUpperCase() }}</h5>
      <div class="horizontal">
        <FontPicker :class="['control']"
                    ref="fontPicker"
                    @open="toggleSubNav('fontPicker')"
                    :font="appliedOptions.fontFamily"
                    @fontPick="apply({'fontFamily':$event})"/>
      </div>
    </div>

    <!-- Alignment options -->
    <div :class="['panel fornode', node?.type !== 'default' ? 'inactive' : 'active']">
      <div>
        <label>{{ $t('options.align') }}</label>
        <div class="horizontal alignment">
          <span :class="['control',!appliedOptions.align || appliedOptions.align==='left' ? 'active' : 'inactive']"
                @click="apply({'align':'left'})"><icon-align-left class="white"/></span>
          <span :class="['control',appliedOptions.align==='center' ? 'active' : 'inactive']"
                @click="apply({'align':'center'})"><icon-align-center class="white"/></span>
          <span :class="['control',appliedOptions.align==='right' ? 'active' : 'inactive']" @click="apply({'align':'right'})"><icon-align-right
              class="white"/></span>
          <span :class="['control',appliedOptions.align==='justify' ? 'active' : 'inactive']"
                @click="apply({'align':'justify'})"><icon-align-justify class="white"/></span>
        </div>
      </div>

      <div>
        <label>{{ $t('options.size') }}</label>
        <div class="horizontal">
          <input class="control active" type="range"
                 :value="appliedOptions.fontSize" min="11" max="42" step="2"
                 @focusin="toggleSubNav()"
                 @input="apply({'fontSize': $event.target.value})"/>
        </div>
      </div>
      <div>
        <label>{{ $t('options.color') }}</label>
        <div class="horizontal">
          <ColorPicker class="control active"
                       ref="colorPicker1"
                       @open="toggleSubNav('colorPicker1')"
                       :color="appliedOptions.color"
                       :bg-color="appliedOptions.backgroundColor"
                       :mode="'text'"
                       @colorPick="apply({'color':$event})">
          </ColorPicker>
        </div>
      </div>

      <div>
        <label>{{ $t('options.background') }}</label>
        <div class="horizontal">
          <ColorPicker class="control active"
                       ref="colorPicker2"
                       @open="toggleSubNav('colorPicker2')"
                       :color="appliedOptions.backgroundColor"
                       @colorPick="apply({'backgroundColor':$event})">
          </ColorPicker>
        </div>
      </div>
    </div>

    <!-- Border options -->
    <div :class="['panel fornode border', node?.type === 'audio' ? 'inactive' : 'active']">
      <div>
        <h5>{{ $t('options.border').toUpperCase() }}</h5>
        <div class="horizontal">
          <div :class="['box control',(appliedOptions.border!=='none' && appliedOptions.border) ? 'active' : 'inactive']"
               @click="apply( appliedOptions.border==='none' ? {'border':'1px solid'} : {'border':'none'} )"></div>

          <!--          <div :class="['box control',appliedOptions.border==='none' || !appliedOptions.border ? 'active' : 'inactive']"-->
          <!--               @click="apply({'border':'none'})">-->
          <!--            <div class="box-line"></div>-->
          <!--          </div>-->
        </div>

      </div>

      <div>
        <label>{{ $t('options.color') }}</label>
        <div class="horizontal">
          <ColorPicker class="control active"
                       ref="colorPicker3"
                       @open="toggleSubNav('colorPicker3')"
                       style="display: inline-block"
                       :bg-color="appliedOptions.backgroundColor"
                       :color="appliedOptions.borderColor"
                       @colorPick="apply({'borderColor':$event})">
          </ColorPicker>
        </div>
      </div>
      <!--      <div>-->
      <!--        <label>{{ $t('options.roundness') }}</label>-->
      <!--        <div class="horizontal">-->
      <!--          <input class="control active" type="range" :value="appliedOptions.borderRadius" min="0" max="20"-->
      <!--                 @change="$emit('apply',{'borderRadius':$event.target.value})"-->
      <!--                 @click="apply({borderRadius:$event.target.value })"/>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div :class="['panel fornode styles', node?.type !== 'default' ? 'inactive' : 'active']">
      <h5>{{ $t('options.styles').toUpperCase() }}</h5>
      <div class="horizontal">
        <div class="box control" @click="applyPredef('h1')" @touchstart="applyPredef('h1')">H1</div>
        <div class="box control" @click="applyPredef('h2')" @touchstart="applyPredef('h2')">H2</div>
        <!--        <div class="box control" @click="applyPredef('h3')">H3</div>-->
        <div class="box control" @click="applyPredef('hi1')" @touchstart="applyPredef('hi1')"
             style="background:rgba(89, 28, 11, 0.9); color:white;">Hi
        </div>
        <!--        <div class="box control" @click="applyPredef('hi2')" @touchstart="applyPredef('hi2')"-->
        <!--             style="background:rgba(0, 0, 0, 0.9); color:white;">Hi2-->
        <!--        </div>-->
        <div class="box control" @click="applyPredef('default')" @touchstart="applyPredef('default')">P</div>
      </div>
    </div>

    <!-- Stage background options -->
    <div class="panel active">
      <h5>{{ $t('options.background') }}</h5>
      <div class="horizontal">
        <BgPicker class="control active"
                  ref="bgPicker"
                  @open="toggleSubNav('bgPicker')"
                  :background="{background:appliedStageOptions.background, style:appliedStageOptions.style}"
                  @colorPick="applyStage($event)"/>
      </div>
    </div>
    <div class="panel last">
      <MiniView :active-stage="activeStage"
                :split="split"
                :stage-position="stagePosition"
                :nodeSet="nodeSet"
                :background="appliedStageOptions.background"
                @miniview:drag="$emit('miniview:drag', $event)"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, toRefs } from "vue"
import ColorPicker                                                               from '../components/ColorPicker'
import BgPicker                                                                  from "@/components/BgPicker";
import FontPicker                                                                from "@/components/FontPicker";
import { nodeModel, nodeOptions, predefNodeStyles, stageModel }                  from "@/models";
import MiniView                                                                  from "@/components/MiniView";


export default {
  name: 'OptionsPanel',
  components: {
    MiniView,
    FontPicker,
    BgPicker,
    ColorPicker
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    // Single node
    node: {
      type: Object,
      default: () => {
        return nodeModel
      }
    },
    split:  {
      type: Boolean,
      default: false
    },
    // Current stage
    stage: {
      type: Object,
      default: () => {
        return stageModel
      }
    },
    // Current stage position. A direct prop, not from store (too slow)
    stagePosition: {
      type: Object,
      default: () => {
        return stageModel.position
      }
    },
    // Current active stage (split)
    activeStage: {
      type: Number,
      default: 0
    },
    // Required to show mini nodes on mini stage
    nodeSet: {
      type: Array,
      default: () => []
    }
  },

  emits: [
    'update:nodeoptions',
    'update:stageoptions',
    'miniview:drag',
    'load',
    'save',
    'apply',
    'centerStage'
  ],

  setup(props, {emit}) {

    const panel = ref(null)
    let align = ref('') // change to right if viewport resizes <
    let editor = null
    let editorSize = ref(null)

    // Panel refs
    // Close any other subNav / track when one is opened
    const opRefs = reactive({
      fontPicker: null,
      colorPicker1: null,
      colorPicker2: null,
      colorPicker3: null,
      bgPicker: null
    })

    // Watch data/options and apply
    const appliedOptions = computed({
      get: () => {
        return {...nodeOptions, ...props.node.options}
      },
      set: (val) => emit('update:nodeoptions', {...props.node, ...val})
    });

    // Watch data/options and apply
    const appliedStageOptions = computed({
      get: () => {
        return {...stageModel, ...props.stage}
      },
      set: (val) => emit('update:stageoptions', {...props.stage, ...val}),
    });

    function apply(opts) {
      emit('update:nodeoptions', opts)
      // If a bg color has been set, make sure to adjust foreground
      // Well, it's a usability question
      if (!!(opts.backgroundColor)) {
        opRefs.colorPicker1.autoColor(opts.backgroundColor)
      }
    }

    function applyPredef(id) {
      apply(predefNodeStyles.find(s => s.id === id))
    }

    function applyStage(opts) {
      emit('update:stageoptions', {...props.stage, ...opts})
    }

    // Recenter the main stage
    function centerStage() {
      emit('centerStage')
    }


    function toggleSubNav(theOneOpened) {
      Object.keys(opRefs).forEach(el => {
        if (el !== theOneOpened) opRefs[el].close()
      })
    }

    // -------------------------------
    // Events

    function _initEvents() {
      // Try not to bubble any event up to stage
      panel.value.addEventListener('click', _eDisablePropagation)
      panel.value.addEventListener('tap', _eDisablePropagation)
    }

    function _removeEvents() {
      panel.value.removeEventListener('click', _eDisablePropagation)
      panel.value.removeEventListener('tap', _eDisablePropagation)
    }

    function _eDisablePropagation(e) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }

    onMounted(() => {
      // Elements

      nextTick(() => {
        // Editor may not be ready...
        editor = document.getElementsByName('editor-0')[0]
        _initEvents()
      })
    })

    onBeforeUnmount(() => {
      _removeEvents()
      // onRemove(editor)
    })


    return {
      panel,
      align,
      editorSize,
      apply,
      applyPredef,
      applyStage,
      appliedOptions,
      appliedStageOptions,
      centerStage,
      toggleSubNav,
      getDimensions: () => {
        return panel.value.getBoundingClientRect()
      },
      // Refs
      ...toRefs(opRefs)

    }
  }
}


</script>

<style lang="scss">
@import "../styles/variables";

// Default is dark
.options-panel {
  position               : fixed;
  z-index                : 990;
  right                  : 0;
  bottom                 : 0;
  left                   : auto;
  background             : var(--primary-color-trans);
  color                  : var(--text-color);
  backdrop-filter        : blur(8px);
  border-top-left-radius : 10px;
  display                : flex;
  flex-direction         : row;
  text-align             : left;
  font-size              : 11.5px;
  padding-top            : 2px;
  padding-bottom         : 2px;

  // Segments
  .panel {
    border-left    : 1px solid #888;
    padding        : 0 10px 0 10px;
    display        : flex;
    flex-direction : row;
    align-items    : center;

    // Panels that should be activated only when a node is selected

    &.first {
      border : none;
    }

    // MiniView is here
    &.last {
      pointer-events : all;
      opacity        : 1;
    }

    // Inactive default
    pointer-events : none;
    opacity        : 0.5;

    // Default state: enabled
    &.active {
      pointer-events : all;
      opacity        : 1;
    }

  }

  // Vertical flexed
  .vertical {
    display         : flex;
    flex-direction  : column;
    justify-content : center;
  }

  // Horizontal flexed div container
  .horizontal {
    display         : flex;
    flex-direction  : row;
    min-height      : 34px;
    justify-content : center;
    align-items     : center;
    height          : 80%;
  }

  // --------
  // Controls
  .control {
    cursor : pointer;

    &.inactive {
      opacity : 0.5;
    }
  }

  // Special: Alignment
  .alignment {
    margin-right : 5px;

    svg {
      width   : 30px;
      height  : 30px;
      margin  : 5px 0 0 0;
      //border  : 1px solid;
      padding : 5px;
    }
  }

  // -------------
  // Active state
  &.active {
    display : flex;
    opacity : 1;

  }

  // Links
  a {
    color           : var(--text-color);
    font-weight     : bold;
    text-decoration : none;
  }

  // Boxes / simple buttons
  .box {
    background    : transparent;
    border        : 1px solid var(--text-color);
    border-radius : 2px;
    width         : 30px;
    height        : 30px;
    line-height   : 30px;
    text-align    : center;
    margin        : 0 5px;
    transition    : all 0.4s;

    &:hover {
      background : white;
      color      : var(--primary-color);
    }

    // inner vertical line
    .box-line {
      width         : 28px;
      height        : 28px;
      border-bottom : 1px solid var(--text-color);
      transform     : translateY(-14px) translateX(5px) rotate(45deg);
    }
  }

  svg {
    fill         : var(--text-color);
    stroke-width : 0;

    line {
      stroke : var(--text-color);
    }
  }

  // Track (sub-menus)
  .track {
    position      : absolute;
    bottom        : 46px;
    left          : auto;
    width         : 300px;
    text-align    : center;
    background    : var(--background-trans);
    padding       : 10px;
    box-shadow    : 0 0 10px rgba(0, 0, 0, 0.3);
    z-index       : 101;
    border-radius : 4px;

  }

  // Todo: cleanup the optionspanel layout
  h5, label {
    //font-weight : bold;
    display        : none;
    text-transform : uppercase;

  }

}

// ------------------------------------------------------------------
// Pickers [Belongs to picker-classes]
// ------------------------------------------------------------------
.colorPicker {
  .colorInner {
    width                 : 23px;
    height                : 23px;
    -webkit-border-radius : 27px;
    -moz-border-radius    : 27px;
    border-radius         : 27px;
  }

  .picker {
    width  : 100%;
    height : 100%;
  }

  .color-main {
    // svg
    svg {
      width : 24px;
      //margin-right : 10px;

      .st0 {
        fill : #FFFFFF;
      }
    }
  }
}

// ------------------------------------------------------------------
// Align right
// ------------------------------------------------------------------

@media (max-width : 740px) {
  .options-panel {
    right          : 0;
    top            : 0;
    width          : auto;
    flex-direction : column;

    .panel {
      flex-direction : column;
      border-bottom  : 1px solid;
      padding        : 5px 15px;

      // The miniview is here
      &.last {
        height         : 100px;
        align-items    : center;
        border         : none;
        pointer-events : all;
        opacity        : 1;
      }
    }

    // Aligns
    .alignment {
      display        : flex;
      flex-direction : column;

      span:first-child {
        margin-top : 10px;
      }
    }

    // Styles 2x2
    .styles > .horizontal {
      display   : flex;
      flex-flow : wrap;
      flex-wrap : wrap;
      width     : 80px;

      .box {
        margin : 5px;
      }
    }

    // SubMenu
    .track {
      bottom : auto;
      left   : auto;
      right  : 100px;
    }

  }
}

// ------------------------------------------------------------------
// Inverted - on dark backgrounds [todo: changed class names]
// ------------------------------------------------------------------
//.options-panel.dark-- {
//  //
//
//  background      : var(--text-color-trans);
//  color           : var(--primary-color);
//  border-color    : var(--primary-color);
//  backdrop-filter : blur(8px);
//
//  .box {
//    border-color : var(--primary-color);
//  }
//
//  svg {
//    fill : var(--primary-color);
//  }
//
//  // Tracks & Pickers [!]
//  .colorPicker, .fontPicker {
//    .track {
//      background      : var(--text-color-trans);
//      backdrop-filter : blur(8px);
//
//      li {
//        border-color : var(--primary-color);
//      }
//    }
//
//    .color-main {
//      background : rgba(0, 0, 0, 0.2);
//    }
//
//    li.divider {
//      background : var(--primary-color);
//    }
//  }
//
//  .box, .box-line {
//    border-color : var(--primary-color);
//  }
//
//  a {
//    color : var(--text-color);
//  }
//
//  svg {
//    fill : var(--primary-color);
//
//    line {
//      stroke : var(--primary-color);
//    }
//  }
//
//  #miniEditor {
//    .tinyNode {
//      background : white;
//    }
//
//    .ov-inner--marker svg {
//      fill : white;
//    }
//  }
//
//}

// ------------------------------------------------------------------
// Semi touch friendly slider
// ------------------------------------------------------------------
$sliderSize : 31px;
// now for the good stuff 🎉
input[type="range"] {
  outline       : 0;
  border        : 0;
  border-radius : 500px;
  width         : $sliderSize; //31px;
  max-width     : 100%;
  //margin        : 0 4px;
  //transition: box-shadow 0.2s ease-in-out;
  transition    : width 0.3s ease-in-out;

  // Expand on focus
  &:focus, &:active {
    width : 140px;
  }

  // Chrome
  @media screen and (-webkit-min-device-pixel-ratio : 0) {
    & {
      overflow           : hidden;
      //height             : $sliderSize;
      -webkit-appearance : none;
      background-color   : #ddd;
    }
    &::-webkit-slider-runnable-track {
      height             : $sliderSize;
      -webkit-appearance : none;
      color              : #444;
      transition         : box-shadow 0.2s ease-in-out;
    }
    &::-webkit-slider-thumb {
      border             : 2px solid white;
      width              : $sliderSize;
      -webkit-appearance : none;
      height             : $sliderSize;
      cursor             : ew-resize;
      background         : #fff;
      box-shadow         : -340px 0 0 320px #1597ff, inset 0 0 0 40px #1597ff;
      border-radius      : 50%;
      transition         : box-shadow 0.2s ease-in-out;
      position           : relative;

      &:after {
        content  : "x";
        color    : white;
        position : absolute;
      }

      // top: 1px;
    }
    &:active::-webkit-slider-thumb {
      background : #fff;
      box-shadow : -340px 0 0 320px #1597ff, inset 0 0 0 3px #1597ff;
    }
  }

}


</style>
