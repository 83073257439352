<template>
  <div class="colorPicker">

    <OptionsSubPanel :open="open">
      <ul ref="color">
        <li class="color-main">
          <svg version="1.1" @click="toggleColor" @touchend="toggleColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
          <g>
            <g>
              <path style="fill: white;" d="M4.7,26.6C2,23.7,0.5,20,0.5,16c0-8.5,7-15.5,15.5-15.5c4,0,7.7,1.5,10.6,4.2L4.7,26.6z"/>
              <path d="M16,1c3.7,0,7.1,1.3,9.8,3.7L4.7,25.8C2.3,23.1,1,19.7,1,16C1,7.7,7.7,1,16,1 M16,0C7.2,0,0,7.2,0,16
                c0,4.4,1.8,8.4,4.7,11.3L27.3,4.7C24.4,1.8,20.4,0,16,0L16,0z"/>
            </g>
            <g>
            <path style="fill: black;" d="M16,31.5c-4,0-7.7-1.5-10.6-4.2L27.3,5.4C30,8.3,31.5,12,31.5,16C31.5,24.5,24.5,31.5,16,31.5z"/>
              <path style="fill: black;" d="M27.3,6.1C29.7,8.8,31,12.3,31,16c0,8.3-6.7,15-15,15c-3.7,0-7.2-1.3-9.9-3.7L27.3,6.1 M27.3,4.7L4.7,27.3
              C7.6,30.2,11.6,32,16,32c8.8,0,16-7.2,16-16C32,11.6,30.2,7.6,27.3,4.7L27.3,4.7z"/>
          </g>
        </g>
      </svg>
        </li>

        <li v-for="color in palette" @click="pickColor(color)" @touchend="pickColor(color)" :key="color" :style="`background:${color}`"></li>

      </ul>
    </OptionsSubPanel>

    <a class="color" @click="toggle">
      <div v-show="mode !== 'text'" class="colorInner" :style="`background-color: ${appliedColor};`"></div>
      <div v-show="mode === 'text'" class="colorInner" :style="`color: ${appliedColor};`">T</div>
    </a>
  </div>
</template>
<script>
/* eslint-disable */
//https://codepen.io/lenymo/pen/rwmBGq
//https://codereview.stackexchange.com/questions/69887/drawing-a-color-wheel-faster
import { computed, ref } from "vue"
import { contrast }      from "@/utils";
import OptionsSubPanel   from "@/components/OptionsSubPanel";
import Text              from "@/components/icons/jam/components/text";

export default {
  name: 'ColorPicker',
  components: {Text, OptionsSubPanel},
  emits: ['update:modelValue', 'colorPick', 'open'],
  props: ['color', 'bgColor', 'inverted', 'mode'],
  setup(props, {emit}) {

    let open = ref(false)
    let color = ref('rgb(255, 255, 255)')
    let picker = ref(null)

    // Indicates whether user leaves it automatic (contrast)
    let autoContrast = true

    // Palette
    // const palette = ref(['#ffffff', '#d1d1d1', '#717171', '#9488417', '#000000', '#da565b', '#56beda', '#567cda', '#dad556', '#9356da'])
    // const palette = ref(['#ffffff', '#03071E', '#370617', '#6A040F', '#9D0208', '#D00000', '#DC2F02', '#E85D04', '#F48C06', '#FAA307'])
    const palette = ref(['#ffffff', '#000000', '#f94144', '#f3722c', '#f9844a', '#f9c74f', '#90be6d', '#43aa8b', '#4d908e', '#577590',
      '#da565b', '#56beda', '#567cda', '#dad556', '#9356da'])


    // Color data props
    const appliedColor = computed({
      get: () => {
        // autoContrast = (!props.color || props.color !== '#000000' || props.color !== '#ffffff') // turn auto contrast off
        return props.color || '#fff'
      },
      set: (val) => emit('update:modelValue', val),
    });

    /**
     * Toggle show/hide
     * emits to parent view
     */
    function toggle() {
      open.value = !open.value
      if (open.value) emit('open')
    }

    /**
     * Active color pick
     * disables autoContrast
     * @param color
     */
    function pickColor(color) {
      autoContrast = false
      emit('colorPick', color)
      open.value = false
    }


    /**
     * AutoSelect a color, based on background
     *
     */
    function toggleColor() {
      const bgColor = props.bgColor ? props.bgColor : '#000000'
      autoContrast = true
      emit('colorPick', contrast(bgColor))
      toggle()
    }

    /**
     * Auto-Select color (public)
     * @param bgColor HEX string colour value
     */
    function autoColor(bgColor) {
      if (!autoContrast) return // only apply if user wants this
      emit('colorPick', contrast(bgColor))
    }


    return {
      palette,
      picker,
      open,
      color,
      appliedColor,
      toggle,
      toggleColor,
      pickColor,
      autoColor,
      close: () => {open.value = false}
    }
  }


}
</script>
<style lang="scss" scoped>
@import "src/styles/variables";

$defaultBg  : rgba(50, 50, 50, 0.9);
$invertedBg : #eee;

.colorPicker {
  position    : relative;
  z-index     : 100;
  font-size   : 20px;
  font-family : one;
  text-align  : center;

  ul {
    margin  : 5px 0 0 0;
    padding : 0;
  }

  li, .color {
    // Dots
    list-style    : none;
    float         : left;
    border        : 2px solid #aaaaaa;
    margin        : 2px;
    padding       : 2px;

    cursor        : pointer;
    display       : block;
    width         : 31px;
    height        : 31px;
    border-radius : 50%;
  }

}


</style>
