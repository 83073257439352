<template>
  <div v-html="icons[name]"></div>
</template>

<script>
export default {
  props: {
    name: { type: String }
  },
  data: function () {
    return {
      icons: {
        download: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"/><path fill="none" d="M0 0h24v24H0z"/></svg>',
        mic: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
        pause: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
        play: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 5v14l11-7z"/><path d="M0 0h24v24H0z" fill="none"/></svg>',
        save: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
        stop: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 6h12v12H6z"/></svg>',
        volume: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ar-icon {
  fill             : #747474;
  border-radius    : 50%;
  border           : 1px solid #0576cd;
  background-color : #FFFFFF;
  padding          : 5px;
  cursor           : pointer;
  transition       : .2s;
  display          : inline-block;
  margin           : 0 2px;

  &--no-border {
    border        : 0;
    border-radius : 0;
    padding       : 0;
    }

  &--rec {
    fill             : white;
    background-color : #FF6B64;
    border-color     : transparent;
    }

  &--pulse {
    animation : ripple .5s linear infinite;
    @keyframes ripple {
      0% {
        box-shadow : 0 0 0 0 rgba(red, 0.1),
        0 0 0 1px rgba(red, 0.1),
        0 0 0 5px rgba(red, 0.1);
        }
      100% {
        box-shadow : 0 0 0 0 rgba(red, 0.1),
        0 0 0 10px rgba(red, 0.1),
        0 0 0 20px rgba(red, 0);
        }
      }
    }

  &__xs {
    width       : 20px;
    height      : 20px;
    line-height : 6px;
    }

  &__sm {
    width       : 30px;
    height      : 30px;
    line-height : 30px;
    }

  &__lg {
    width       : 45px;
    height      : 45px;
    line-height : 45px;
    box-shadow  : 0 2px 5px 1px rgba(158, 158, 158, 0.5);
    }
  }

</style>
