<template>
  <div class="rte">

    <div class="top">
      <icon-close v-show="mode !== 'nice'" v-touch="()=>$emit('rte:close')" class="control close"/>
      <div class="title" v-if="node" v-html="node?.content.substr(0,100)"></div>
    </div>

    <bubble-menu class="menu" :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
      <nav>
        <div class="controls" v-if="editor">

          <icon-align-left @click="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"/>
          <icon-align-center @click="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"/>
          <icon-align-right @click="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"/>
          <icon-align-justify @click="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"/>
          <span class="div"></span>
          <!--        <button @click="editor.chain().focus().unsetTextAlign().run()">-->
          <!--          unsetTextAlign-->
          <!--        </button>-->
          <icon-font-bold @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }"/>
          <icon-font-italic @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }"/>
          <icon-font-underline @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }"/>
          <icon-font-strike @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }"/>
          <span class="div"></span>
          <icon-font-h1 @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"/>
          <icon-font-h2 @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"/>
          <icon-font-h3 @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"/>
          <span class="div"></span>
          <icon-li @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }"/>
          <icon-ol @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }"/>
          <span class="div"></span>
          <icon-quote @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }"/>

          <!--        <select>-->
          <!--          <option value="1">1</option>-->
          <!--          <option value="1.25">1.25</option>-->
          <!--          <option value="1.5">1.5</option>-->
          <!--          <option value="2">2</option>-->
          <!--        </select>-->

        </div>

      </nav>
    </bubble-menu>

    <editor-content :editor="editor" id="editorjs" @click="editor.commands.focus()"/>

    <div class="count" v-if="editor">
      {{ editor.storage.characterCount.words() }} {{ $t('words') }}
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import { useEditor, EditorContent, BubbleMenu } from '@tiptap/vue-3'
import StarterKit                               from '@tiptap/starter-kit'
import TextAlign                                from '@tiptap/extension-text-align'
import Underline                                from "@tiptap/extension-underline"
import CharacterCount                           from '@tiptap/extension-character-count'
import { onBeforeUnmount, onMounted, watch }    from "vue";

export default {
  components: {
    EditorContent,
    BubbleMenu
  },
  props: {
    node: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: ''
    }
  },

  setup(props, {emit}) {
    /**
     * Create HTML from editor block
     * @param blocks
     * @returns {string}
     */
    const convertDataToHtml = (blocks) => {
      var convertedHtml = "";
      blocks.map(block => {

        switch (block.type) {
          case "header":
            convertedHtml += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
            break;
          case "embded":
            convertedHtml += `<div><iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;
            break;
          case "paragraph":
            convertedHtml += `<p>${block.data.text}</p>`;
            break;
          case "delimiter":
            convertedHtml += "<p style=\"text-align:center;\">* * *</p>";
            break;
          case "image":
            convertedHtml += `<img class="img-fluid" src="${block.data.file.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`;
            break;
          case "list":
            convertedHtml += "<ul>";
            block.data.items.forEach(function (li) {
              convertedHtml += `<li>${li}</li>`;
            });
            convertedHtml += "</ul>";
            break;
          default:
            console.log("Unknown block type", block.type);
            break;
        }
      });
      return convertedHtml;
    }
    const content = (props.node?.html && props.node?.html.blocks) ? convertDataToHtml(props.node?.html.blocks) : props.node?.html ? props.node?.html : ''

    const editor = useEditor({
      content: content,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          }
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Underline,
        CharacterCount,
        // BubbleMenu.configure({
        //   element: document.querySelector('.menu'),
        // }),
      ],
      autofocus: "end"
      // triggered on every change
      // onUpdate: ({ editor }) => {
      //   const json = editor.getJSON()
      //   console.info('ED update -> ', json)
      //   // send the content to an API here
      // },
    })


    watch(() => props.node?.html, async (nv, ov) => {
      if (!editor) return
      if (nv && nv !== ov) {
        const d = (nv.blocks) ? convertDataToHtml(nv.blocks) : nv
        setData(d)

      }
    })

    const setData = (data) => {
      editor.value.commands.clearContent()
      editor.value.commands.insertContent(data)
    }

    const getData = async () => {
      const json = await editor.value.getJSON()
      return {...props.node, ...{html: json}}
    }


    onBeforeUnmount(() => {
      // ...
      // emit('rte:datachange', {...props.node, ...{html: editor.value.getJSON()}})  // use the old value
      editor.value.destroy()
    })


    return {
      editor,
      setData,
      getData,
      getHTML: () => {
        return editor.value.getHTML()
      }
    }
  },
}
</script>

<style lang="scss">
.ProseMirror {
  > * + * {
    margin-top : 0.75em;
  }
}

.rte {
  position   : relative;
  height     : 100%;
  //padding-top   : 42px;
  //margin-bottom : 3em;
  background : var(--text-color);
  color      : var(--background-color);
  overflow   : auto;

  #editorjs {
    margin      : 3rem;
    font-family : typewriter;
    font-size   : 16.5px;
    min-height  : calc(100% - 6rem);
    //padding-bottom : 12vh; // ???

    [contenteditable] {
      outline : 0 solid transparent;
    }

  }

  nav {
    min-width       : 560px;
    background      : rgba(0, 0, 0, 0.05);
    backdrop-filter : blur(6px);
    padding         : 5px 10px;
    border-radius   : 4px;
    background      : var(--text-color);
    box-shadow      : rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    // Items
    display         : flex;
    align-items     : center;

    .div {
      border-right : 1px solid #ccc
    }

    span {
      display       : inline-block;
      margin-right  : 10em; // main nav.
      white-space   : nowrap;
      overflow      : hidden;
      text-overflow : ellipsis;
    }

    .controls {
      display      : flex;
      color        : #888;
      fill         : #888;
      margin-right : 2rem;

      .is-active {
        background    : rgba(0, 0, 0, 0.5);
        border-radius : 2px;
        color         : var(--background-color);
        fill          : var(--background-color);

      }

      // icon sizes
      svg {
        width  : 32px;
        height : 32px;
      }

    }

    .title {
      font-size     : 11px;
      width         : 220px;
      white-space   : nowrap;
      overflow      : hidden;
      text-overflow : ellipsis;
      margin-right  : 1.5rem;
    }

    // .divider
    .div {
      margin : 0 7.5px;
    }

  }

  .top {
    position        : fixed;
    left            : 50vw;
    right           : 0;
    top             : 0;
    display         : flex;
    height          : 3em;
    background      : rgba(0, 0, 0, 0.05);
    backdrop-filter : blur(6px);
    align-items     : center;
    z-index         : 10;

    .close {
      margin : 0 1rem;
      color  : #888;
      fill   : #888;
    }
  }

  .count {
    font-size       : 11px;
    position        : fixed;
    bottom          : 1vh;
    right           : 3rem;
    z-index         : 501;
    background      : rgba(255, 255, 255, 0.2);
    backdrop-filter : blur(5px);
    padding         : 5px;
    border-radius   : 5px;
  }
}

//
.dark.rte {
  color      : var(--text-color);
  background : var(--background-color);

  nav {
    background : #212121;

    .controls, .close {
      color : #888;
      fill  : #888;

      .is-active {
        //background : rgba(255, 255, 255, 0.1);
        color : var(--text-color);
        fill  : var(--text-color);
      }
    }
  }
}

</style>
