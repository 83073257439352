<script setup>
import Connectors                                                                         from "@/components/Connectors.vue";
import { computed, nextTick, onBeforeUpdate, onMounted, onUpdated, reactive, ref, watch } from "vue";
import { uuid }                                                                           from "vue-uuid";
import { nodeModel, nodeOptions, predefNodeStyles, stageModel }                           from "@/models";
import Screen                                                                             from "@/components/Screen";
import Node                                                                               from "@/components/Node";
import Controller                                                                         from "@/components/Controller";
import { useProjectStore }                                                                from "@/store/project";
import { storeToRefs }                                                                    from "pinia";
import { appStateStore }                                                                  from "@/store/appState";

/* eslint-disable*/

const props = defineProps({
	stage:      {
		type:    Object,
		default: () => { return {...stageModel}}
	},
	split:      {
		type:    Boolean,
		default: false
	},
	splitIndex: {
		type:    Number,
		default: 0
	},
	rteOpen:    {
		type:    Boolean,
		default: false
	},

})

const emits = defineEmits([
	'stage:focus',
	'stage:update',
	'stage:position',
	'stage:addnode',
	'stage:removenode',
	'stage:updatenode',
	'stage:nodeselect',
	'stage:nodedeselect',
	'stage:addconnector',
	'stage:removeconnector',
	'stage:updateconnectoroptions',
	'stage:leveldown',
	'stage:levelup',
	'node:moveleft',
	'node:moveright',
	'stage:opensplit',
	'stage:closesplit',
	'stage:openrte',
	'stage:save'
])


const appState = appStateStore()
const {stagePanEnabled, helpEnabled, currentHelpItem} = storeToRefs(appState)

let screenRef = ref(undefined)  // current screen reference

// Line Options [defaults]
let lineOptions = {dash: true, size: 1, color: 'rgba(0,0,0,0.7)', padStart: 0, padEnd: 0} //, startPlug: 'disc', endPlug: 'disc'

// @deprecated EditMode = if true, then ???
const editMode = ref(false)

// @deprecated: events get locked, if the stage is zoomed !== 1. No touch.pointer events
let eventsLocked = ref(false)  // stop events for editor (e.g. node add, delete, etc.) -- when scale < 1 normally, unless I got it sorted properly.

// The main data ---------------------------------------------
const projectStore = useProjectStore()         // CURRENT project
const {project} = storeToRefs(projectStore)

const currentStage = computed({
	get: () => props.stage,
	set: (val) => emits('stage:update', val) // model update
})      // currently active stage

// Current parent node depends on the view (0/1)
// const currentParentNode = computed(() => project.value.currentParentNodes[props.splitIndex])
const currentParentNode = computed(() => project.value?.nodes.find(n => n.id === project?.value.currentParentNodes[props.splitIndex]?.id),)

// If the number of nodes changes (add, delete), reset the nodeRefs
// todo: should be picked up by an update event (_key change?)
watch(() => [project.value.nodes, project.value.connectors], () => {
	nodeRefs = []
	connectorRefs = []
})


// -----------------------------------------------------------------
// Screen events, Navigation
// -----------------------------------------------------------------
/**
 * Pan end triggers save of current stage position
 * @param payload
 */
const onPanEnd = (payload) => {
	// Update stage
	currentStage.value = {...currentStage.value, ...{position: payload}}
}

// -----------------------------------------------------------------
// ContextMenu / Controller
// -----------------------------------------------------------------
// ContextMenu vars
const contextMenu = reactive({
	x:     0,
	y:     0,
	nodeX: 0,
	nodeY: 0,
	show:  false
})

const releaseContextMenu = () => {
	// Be gone context [issue on touch!]
	if (contextMenu.show) contextMenu.show = false
}

// -----------------------------------------------------------------
// Node handling
// -----------------------------------------------------------------
let nodeRefs = []
let selectedNode = ref(null)

/**
 * Show the context control on the tapped position
 * and store the position for a new node. This prepares for 'addNodeType'
 * @param e
 */
const addNode = (e) => {

	// Editor action only
	// if (e.target.getAttribute('name') !== 'editor-0' && e.target.getAttribute('name') !== 'editor-1') return
	// For Safari -> target = $(document.elementFromPoint(touch.pageX - window.pageXOffset, touch.pageY - window.pageYOffset));
	// https://github.com/ftlabs/fastclick/issues/57

	// Absolute position of touch/mouse event
	let x = e.type === "touchstart"
		? props.splitIndex === 0 ? e.changedTouches[0].clientX : e.changedTouches[0].clientX - window.innerWidth / 2
		: props.splitIndex === 0 ? e.clientX : e.clientX - window.innerWidth / 2

	let y = e.type === "touchstart" ? e.changedTouches[0].clientY : e.clientY

	// Check, if help is active and user can click here
	if (helpEnabled.value || currentHelpItem.value?.lock) {
		if (!(
			x > currentHelpItem.value.lock?.x
			&& x < currentHelpItem.value.lock?.x1
			&& y > currentHelpItem.value.lock?.y
			&& y < currentHelpItem.value.lock?.y1
		)) return
	}

	// Add offsets for node position : use current stage transform
	const style = window.getComputedStyle(e.target)
	const matrix = new DOMMatrixReadOnly(style.transform)
	const size = e.target.getBoundingClientRect()
	const stagePos = {x: matrix.m41, y: matrix.m42, scale: matrix.a, width: size.width, height: size.height}
	// Add offsets for node position
	const nodeX = (Math.abs(stagePos.x) + x) / stagePos.scale
	const nodeY = (Math.abs(stagePos.y) + y) / stagePos.scale

	// Open Context menu
	contextMenu.show = true
	contextMenu.x = x - 16
	contextMenu.y = y - 16
	// Todo: -50 is an assumption of half the size of a new node.
	contextMenu.nodeX = nodeX - 50
	contextMenu.nodeY = nodeY + 10
}

/**
 * Execute the user's wish to add a node of certain type
 * @param types
 */
const addNodeType = (types) => {
	const {nodeX, nodeY} = contextMenu
	let {type, subType} = types
	if (!type) type = 'default'
	// Create a new node in dataset.
	const __id = uuid.v4()
	emits('stage:addnode', {
		_new:     true,
		content:  '...',
		id:       __id,
		stageId:  props.stage.id,
		type:     type,
		position: {...nodeModel.position, ...{x: nodeX, y: nodeY}},
		options:  subType ? predefNodeStyles.find(ns => ns.id === subType) : nodeOptions // breaks focus.
	})
	// Be gone...
	contextMenu.show = false

}

const removeNode = (payload) => {
	emits('stage:removenode', {node: payload.component})
}

/**
 * Update node content and options(?)
 * @param e
 */
const updateNode = (e) => {
	// Todo: firing too often!!!
	// ...
	if (selectedNode.value && e.id !== selectedNode.value.id) {
		return
	}
	selectedNode.value = {...selectedNode.value, ...e}
	// console.info('STAGE ::: update', e)
	emits('stage:updatenode', {node: selectedNode.value}, e.attachment)
}

/**
 * Set selected node to active
 * Toggle Optionspanel
 */
const selectActiveNode = payload => {
	emits('stage:nodeselect', payload)
	// Deselect all other nodes (for now), except for this
	deselectAllNodes(payload)
	// Set
	selectedNode.value = payload.component   // Data component, NOT ref!
}

/**
 * Deselect all active nodes (on outside tap) and deactivate optionspanel
 * Todo: make this obsolete. Use node.vue
 * @param e
 */
const deselectActiveNodes = (e) => {
	// if (e !== null && e.target.getAttribute('name') !== 'editor-0' && e.target.getAttribute('name') !== 'editor-1') return
	if (e !== null && e.target.getAttribute('name') === 'node') return
	try {
		nodeRefs.forEach(n => {
			n.toggleActive(false, null)
		})
	} catch (e) {
		console.warn(e)
	}
	// Resume stage pan/zoom (unlock)
	// TODO: Might interfere with help!!!!
	if (!helpEnabled.value)
		stagePanEnabled.value = true
	editMode.value = false
	// Disable options in oPanel
	emits('stage:nodedeselect')
	// emits('stage:disableoptionspanel')
}

/**
 * Some node entered editable state
 * This means to lock any movement / panning / zooming
 */
function setEditableNode(val) {
	if (val) {
		// Lock stage / screen from moving
		stagePanEnabled.value = false
		// Note: unlock happens @deselectActiveNode()
		// Dim stage and others
		editMode.value = true
	}
}

const onLevelDown = (payload = {component: null}) => {
	emits('stage:leveldown', payload)
	// Center stage? only on new
	// screenRef.value.centerStage()
	refreshConnectors()
}

const onLevelUp = () => {
	emits('stage:levelup', props.splitIndex)
	refreshConnectors()
}

/**
 * Open split view.
 * Before opening, center current node (parent)
 * @param e
 */
const onOpenSplit = (node) => {
	emits('stage:opensplit', node)
}

const onOpenRTE = (node) => {
	emits('stage:openrte', node)
}

const setNodeRefs = el => {
	if (el)
		nodeRefs.push(el)
}

const deselectAllNodes = (except) => {
	nodeRefs.forEach(n => {
		// Exception is a bit silly. But it works.
		if (except && except.component !== n.component)
			n.toggleActive(false, null)
	})
}

// Center stage [50/50]
const centerStage = () => {
	screenRef.value.centerStage()
}

const moveStage = (e) => {
	screenRef.value.panTo(e.pos, e.set)
}

// Connectors ------------------------------------------------------

// References to UI SVG connectors
let connectorRefs = []
const setConnectorRefs = el => {
	if (el) {
		connectorRefs.push(el)
	}
}

/**
 * Check if a connection exists
 * @param fromId
 * @param toId
 * @return Node{}
 * @private
 */
const _hasConnection = (fromId, toId) => {
	return project.value.connectors.find(conn => {
		return ((conn.from === fromId && conn.to === toId) || (conn.to === fromId && conn.from === toId)) ? conn : null
	})
}

/**
 * Build connector lines between elements
 * this will check for all connections in the project array
 * (we may shove them back into the elements itself?)
 * @param rebuild
 */
const refreshConnectors = () => {

}

/**
 * Check node collision on drag
 * @param payload JSON expects a component (Node)
 * @param index INT the node index (array)
 */
function checkCollision(payload, index) {
	const curNodeRef = nodeRefs[index]
	// Faster, but may be empty!!!!!
	if (connectorRefs.length)
		connectorRefs.forEach(c => {
			if (c.to === payload.component.id || c.from === payload.component.id) {
				c.update()
			}
		})

	// let dzRect = dz.value.getBoundingClientRect()
	let _didCollide = false
	let {rect, dragging, component} = payload   // nodeRef == the reference to the class, component == the data object of it
	// Check all other nodes [expensive]
	nodeRefs.forEach(comp => {
		// Check each node
		if (!comp) return
		let bRect = comp.getPosition() // gets x,y,w,h instead of left, top,...
		if (!rect || !bRect) return
		let _collided = !(
			((rect.top + rect.height) < (bRect.y)) ||
			(rect.top > (bRect.y + bRect.h)) ||
			((rect.left + rect.width) < bRect.x) ||
			(rect.left > (bRect.x + bRect.w))
		) && comp.data.id !== component.id

		// Attribute update on hit node
		comp.setCollision(_collided)

		// on Collision AND when node has been dropped
		if (_collided && !dragging && !_didCollide) {
			_didCollide = true
			// Reset original position
			curNodeRef.snapBack()
			// Add or remove a line
			onCollision({fromId: curNodeRef.data.id, toId: comp.data.id})
			// Timeouts are the clueless coder's choice...
			setTimeout(() => {
				comp.setCollision(false)
			}, 100)
		}
	})

	// Todo: really? Emit once
	if (!dragging && !_didCollide) {
		updateNode({...curNodeRef.data, ...{position: curNodeRef.getPosition()}})
	}
}

/**
 * Handle collisions - add or remove a connector
 * @param payload object {toId, fromId} with id of the dragged & hit object
 */
async function onCollision(payload) {
	let {fromId, toId} = payload
	if (!fromId || !toId) return
	const connection = _hasConnection(fromId, toId)

	// Hit action
	if (!connection) {
		let toEl = document.getElementById(toId), fromEl = document.getElementById(fromId)
		if (toEl && fromEl) {

			emits('stage:addconnector', {
				from:    fromId,
				to:      toId,
				stageId: props.stage?.id,
				options: lineOptions
			})
			// deselect nodes
			deselectAllNodes()
			// Check 30ms - or else its too early - node is not yet snapped back
			setTimeout(() => {
				refreshConnectors() // { toId: toId }
			}, 30)

		}
	} else {
		// await projectStore.removeConnector({fromId: fromId, toId: toId})
		emits('stage:removeconnector', {fromId: fromId, toId: toId})
		setTimeout(() => {
			refreshConnectors()
		}, 30)

	}
}


// Init -------------------------------------------------------------

onBeforeUpdate(() => {
	nodeRefs = []
	connectorRefs = []
})

onUpdated(() => {

})

onMounted(() => {
	nextTick(() => {
		deselectAllNodes()
		// Setup connection
		refreshConnectors()
	})
})


</script>

<template>
	<div
		v-if="currentStage"
		:class="['stage', currentStage.style, eventsLocked ? 'events-locked' : '', editMode ? 'edit-mode' : '', project.dirty && 'dirty']"
		:style="`${currentStage.background}`"
	>

		<span class="scale" v-show="+currentStage.position.scale !== 1">{{ (currentStage.position.scale * 100).toFixed(0) }}%</span>

		<Screen
			ref="screenRef"
			:panEnabled="stagePanEnabled"
			:element="`editor-${splitIndex}`"
			:split="split"
			v-model:position="currentStage.position"
			@panning="$emit('stage:position', $event)"
			@panend="onPanEnd"
			@dblclick="addNode($event)"
			@click="deselectActiveNodes"
			v-touch:tap="deselectActiveNodes"
			v-touch:hold="addNode"

		>
			<div ref="editorRef" class="editor" :name="`editor-${splitIndex}`">

				<Connectors
				            :connectors="project.connectors"
				            :nodes="project.nodes.filter(n=>n.stageId === currentStage.id)"/>

				<Node v-for="(node, i) in project.nodes.filter(n=>n.stageId === currentStage.id)"
				      :id="node.id"
				      :key="`x-${node.id}`"
				      :ref="setNodeRefs"
				      :data="node"
				      :scale="currentStage.position.scale"
				      :stage="currentStage"
				      :split-index="splitIndex"
				      :split="split"
				      :rteOpen="rteOpen"
				      :allowedMoveRight="split && splitIndex === 0 && !rteOpen && project.currentParentNodes[1].id !== node.id"
				      :allowedMoveLeft="split && splitIndex === 1 && project.currentParentNodes[0].id !== node.id"
				      :childNum="project.stages ? project.stages.filter(s => s.id === node.id && project.nodes.find(n=>n.stageId === s.id) )?.length : 0"
				      class="panzoom-exclude"
				      @node:update="updateNode"
				      @node:remove="removeNode"
				      @node:active="selectActiveNode($event)"
				      @node:edit="setEditableNode"
				      @node:checkcollission="checkCollision($event, i)"
				      @node:levelDown="onLevelDown($event)"
				      @node:openSplit="onOpenSplit"
				      @node:openRTE="onOpenRTE"
				      @node:moveleft="$emit('node:moveleft', $event)"
				      @node:moveright="$emit('node:moveright', $event)"
				/>
				<!--hack -->
				<div class="contextMenuOvl" v-if="contextMenu.show" v-touch:press="releaseContextMenu"></div>

			</div>

			<Controller class="contextMenu"
			            v-show="contextMenu.show"
			            :style="`left:${contextMenu.x - 45}px;top:${contextMenu.y - 45}px;`"
			            @action-add="addNodeType($event)"
			/>
		</Screen>

		<!-- The infobox top left -->
		<nav v-if="project" :class="['parentNode', currentStage.style, 'noprint']">
			<div class="head">
				<div class="controls">
					<icon-home v-show="splitIndex === 0" v-touch:tap="()=>$emit('stage:save', true)" :class="`control home`"/>
					<icon-arrow-up v-if="currentParentNode && currentParentNode?.id.toString() !== '0'" v-touch:tap="onLevelUp" class="control up"/>
					<icon-close v-if="split && splitIndex === 1" @click="$emit('stage:closesplit')" class="control close"/>
				</div>
				<h5 v-if="splitIndex === 0">{{ project.title }}</h5>
			</div>
			<div class="info" v-if="currentParentNode?.id !== 0">
				<hr class="divider"/>
				<div v-html="currentParentNode?.content"></div>
				<!--        {{ currentParentNode }}-->
			</div>
		</nav>

	</div>
</template>

<style scope lang="scss">
@import "src/styles/variables";

// ---------------------------------------------------------
// Stage (wrapper)

.stage {
	cursor             : move;
	background-size    : cover;
	background         : white fixed center;

	// !
	position           : absolute;
	overflow           : hidden;
	width              : 100vw;
	height             : 100vh;

	-moz-box-shadow    : inset 0 0 4vw 0 rgba(0, 0, 0, 0.5);
	-webkit-box-shadow : inset 0 0 4vw 0 rgba(0, 0, 0, 0.5);

	// Default svg fills
	svg {
		fill         : var(--primary-color);
		stroke-width : 0;

		line {
			stroke : var(--primary-color);
			}
		}

	// No events on nodes
	&.events-locked {
		.node {
			pointer-events : none !important;
			}
		}

	&.edit-mode {

		.node:not(.active) {
			opacity : 0.5;
			}
		}

	}

// ---------------------------------------------------------
// Editor : Todo: try to make it autogrow (window size initially)
.editor {

	overflow : visible;
	}

// ---------------------------------------------------------
// Context Menu (new node)
.contextMenu {
	position      : absolute;
	z-index       : 2500;
	width         : 120px;
	height        : 120px;
	border        : 1px solid rgba(100, 100, 100, 0.5);
	border-radius : 50%;
	overflow      : hidden;
	display       : flex;

	box-shadow    : 0 0 10px rgba(0, 0, 0, 0.2);

	.dark & {
		background : rgba(255, 255, 255, 0.1);
		}

	svg {
		width  : 18px;
		height : 18px;
		fill   : rgba(0, 0, 0, 0.5);

		.dark & {
			fill : rgba(255, 255, 255, 0.5);
			}
		}

	.left, .right {
		flex            : 1;
		text-align      : center;
		line-height     : 130px;
		padding-left    : 25px;
		background      : transparent;
		backdrop-filter : blur(2px);
		margin          : -5px;

		.dark & {
			background : rgba(255, 255, 255, 0.1);
			}
		}

	.left {
		padding-left  : 15px;
		padding-right : 25px;
		margin-right  : 1px;
		border-right  : 1px solid rgba(100, 100, 100, 0.5);
		margin-left   : -27.5px;
		}

	.center {
		position      : absolute;
		border        : 2px solid rgba(100, 100, 100, 0.5);
		border-radius : 50%;
		left          : calc(50% - 22.5px);
		top           : calc(50% - 22.5px);
		width         : 45px;
		height        : 45px;
		text-align    : center;
		line-height   : 52px;
		background    : black;

		.dark & {
			background : white;
			}

		svg {
			fill : rgba(255, 255, 255, 0.5);

			.dark & {
				fill : rgba(0, 0, 0, 0.75);
				}
			}
		}

	}

// This overlay is invisible and just a click-receiver
// to close the context menu on outside touch/click
.contextMenuOvl {
	left       : 0;
	top        : 0;
	width      : 100%;
	height     : 100%;
	position   : absolute;
	z-index    : 1;
	background : transparent;
	}

// ---------------------------------------------------------
// Current parent node info box
.parentNode {
	cursor          : default;
	position        : absolute;
	z-index         : 101;
	top             : 0;
	//width           : 210px; //~~
	max-width       : 33vw;
	left            : 0;
	color           : var(--primary-color);
	box-shadow      : rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
	display         : flex;
	flex-direction  : column;
	line-height     : 0;
	background      : rgba(255, 255, 255, 0.6);
	backdrop-filter : blur(6px);

	> .info {
		flex-grow   : 2;
		padding     : 0.75rem;
		line-height : 1.25;
		}

	> .head {
		display         : flex;
		flex-direction  : row;
		align-items     : flex-start;
		justify-content : space-between;

		h5 {
			flex        : 2;
			padding     : 1em;
			line-height : 1.2;
			}

		svg {
			width      : 30px;
			height     : 30px;
			background : rgba(0, 0, 0, 0.1);
			}

		}

	// Controls
	.control {
		cursor       : pointer;
		margin-right : 1px;
		transition   : all 0.4s ease-in-out;

		&:hover {
			transform : scale(1.1);
			}
		}

	.control.home {
		width   : 30px;
		height  : 30px;
		padding : 5px;
		//margin     : 0 10px 0 0;
		//background : none;
		// make this global, if more:

		}

	.stage.dark & {
		color      : var(--text-color);
		background : rgba(0, 0, 0, 0.2);
		box-shadow : rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;

		svg {
			background : rgba(255, 255, 255, 0.1);
			fill       : var(--text-color);
			}
		}

	// Dirty indicator.
	.dirty .control {
		opacity : 0.5;
		//pointer-events : none;
		}

	}

// Info : scale
.scale {
	position      : absolute;
	top           : 12.5px;
	left          : calc(50% - 20px);
	z-index       : 1111;
	color         : black;
	line-height   : 1;

	padding       : 4px;
	border-radius : 4px;
	background    : rgba(255, 255, 255, 0.4);
	//box-shadow    : rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

	}

.stage.dark .scale {
	background : rgba(0, 0, 0, 0.2);
	color      : white;
	}


</style>
